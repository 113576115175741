/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { logEvent, ErrorResult } from "./util";
import clsx from "clsx";
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { TextField, Link } from "@material-ui/core";
import { get } from "lodash-es";
import { TERMS_PRIVACY } from "config";
import LoadingButton from "components/LoadingButton";
import { getPriceString } from "helpers/format";
import { appStore } from "models";
import CreditCard from "icons/credit-card";

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontWeight: "400",
      fontSize: "16px",
      fontFamily: "Arial, Helvetica, sans-serif",
      lineHeight: "1.5",
      color: "#323555",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#A9ABB9",
      },
    },
    invalid: {
      color: "#FF5858",
    },
  },
};

const PaymentMethodForm = ({ handleSubmitPaymentMethod, buttonText }) => {
  const elements = useElements();
  const stripe = useStripe();
  const [name, setName] = useState("");
  const [postal, setPostal] = useState("");
  const [error, setError] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async event => {
    event.preventDefault();
    setLoading(true);

    try {
      const cardElement = elements.getElement(CardNumberElement);
      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name,
          address: {
            postal_code: postal,
          },
        },
      });
      if (payload.error) {
        console.log("[error]", payload.error);
        setError(payload.error);
      } else {
        console.log("[PaymentMethod]", payload.paymentMethod);
        setError({});
        await handleSubmitPaymentMethod(payload.paymentMethod);
      }
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="modal-info-form">
      <div className="modal-info-block name-on-card">
        <div className="modal-info-block-title">Name on card</div>
        <TextField
          error={!!error.name}
          helperText={get(error, "name.message")}
          className="input-name-on-card"
          variant="outlined"
          fullWidth
          id="name"
          type="text"
          placeholder="Name on Card"
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
          required
        />
      </div>
      <div
        className={clsx("modal-info modal-info-block card-number", {
          error: error.code === "incomplete_number",
        })}>
        <div className="modal-info-block-title">Credit Card number</div>
        <CardNumberElement
          id="cardNumber"
          onBlur={logEvent("blur")}
          onChange={logEvent("change")}
          onFocus={logEvent("focus")}
          onReady={logEvent("ready")}
          options={{ ...ELEMENT_OPTIONS, placeholder: "0000 0000 0000 0000" }}
        />
        <CreditCard className="card-icon" />
        <div className="supported-card-types">
          <img src="/images/visa.png" alt="visa" />
          <img src="/images/mastercard.png" alt="mastercard" />
          <img src="/images/american-express.png" alt="american-express" />
        </div>
      </div>
      <div className="ext-info-row">
        <div
          className={clsx("modal-info modal-info-block expiration", {
            error: error.code === "invalid_expiry_year_past",
          })}>
          <div className="modal-info-block-title">Expiration</div>
          <CardExpiryElement
            id="expiry"
            onBlur={logEvent("blur")}
            onChange={logEvent("change")}
            onFocus={logEvent("focus")}
            onReady={logEvent("ready")}
            options={{ ...ELEMENT_OPTIONS, placeholder: "MM/YY" }}
          />
        </div>
        <div className="modal-info-block cvc">
          <div className="modal-info-block-title">CVV</div>
          <CardCvcElement
            id="cvc"
            onBlur={logEvent("blur")}
            onChange={logEvent("change")}
            onFocus={logEvent("focus")}
            onReady={logEvent("ready")}
            options={{ ...ELEMENT_OPTIONS, placeholder: "000" }}
          />
        </div>
        <div className="modal-info-block zip-code">
          <div className="modal-info-block-title">Zip code</div>
          <TextField
            error={!!error.zipCode}
            helperText={get(error, "zipCode.message")}
            className="input-zip-code"
            variant="outlined"
            fullWidth
            id="postal"
            type="text"
            inputProps={{ pattern: "[0-9]*" }}
            placeholder="00000"
            value={postal}
            onChange={e => {
              setPostal(e.target.value);
            }}
            required
          />
        </div>
      </div>
      {get(error, "message") && (
        <div className="error-message">
          <ErrorResult>{get(error, "message")}</ErrorResult>
        </div>
      )}
      <div className="modal-info-block final-description">
        Your account will be charged {getPriceString(appStore.stripePlan)}/year
        plus taxex, if any on a recurring basis. Your subscription will continue
        until you cancel. See&nbsp;
        <Link href={TERMS_PRIVACY} target="_blank">
          Terms of Use
        </Link>
        &nbsp;for more details. By selecting ‘Submit’ you agreed to this
        recurring charge.
      </div>
      <LoadingButton
        className="btn-red btn-checkout"
        loading={loading}
        type="button"
        variant="contained"
        color="primary"
        disabled={!stripe}
        onClick={handleSubmit}
        fullWidth>
        {buttonText}
      </LoadingButton>
    </form>
  );
};

export default PaymentMethodForm;
