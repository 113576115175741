import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function IconCross(props) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.26627 3.26627C3.62129 2.91124 4.19689 2.91124 4.55192 3.26627L12.7337 11.4481C13.0888 11.8031 13.0888 12.3787 12.7337 12.7337C12.3787 13.0888 11.8031 13.0888 11.4481 12.7337L3.26627 4.55192C2.91124 4.19689 2.91124 3.62129 3.26627 3.26627Z"
        fill="#9B9B9B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7337 3.26627C13.0888 3.62129 13.0888 4.19689 12.7337 4.55192L4.55192 12.7337C4.19689 13.0888 3.62129 13.0888 3.26627 12.7337C2.91124 12.3787 2.91124 11.8031 3.26627 11.4481L11.4481 3.26627C11.8031 2.91124 12.3787 2.91124 12.7337 3.26627Z"
        fill="#9B9B9B"
      />
    </SvgIcon>
  );
}
