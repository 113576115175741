import React, { ReactNode } from "react";
import TopBar from "./TopBar";
import Footer from "./Footer";
import { makeStyles, Grid } from "@material-ui/core";
import Wrapper from "./Wrapper";
import LeftNav from "layouts/LeftNav";

interface Props {
  children?: ReactNode;
}

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
  },
  background: {
    height: 155,
    width: "100%",
    background: "url(/images/background-main.svg) no-repeat",
    backgroundSize: "cover",
    position: "absolute",
    zIndex: -1,
  },
}));

const MainLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TopBar type="main" />
      <Wrapper>
        <div className="main-container">
          <div className={classes.background}></div>
          <div className="main-content">
            <Grid
              spacing={6}
              container
              direction="row"
              wrap="nowrap"
              alignItems="flex-start"
              justify="center">
              <Grid item style={{ height: "100%" }}>
                <LeftNav></LeftNav>
              </Grid>
              <Grid className="right-page-container" item>
                {children}
              </Grid>
            </Grid>
          </div>
        </div>
      </Wrapper>
      <Footer></Footer>
    </div>
  );
};

export default MainLayout;
