import React from "react";
import { Router, Redirect, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import GuardedRoute from "components/GuardedRoute";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { DialogProvider } from "components/Dialog";
import Amplify from "aws-amplify";
import theme from "theme";

import SingleLayout from "layouts/SingleLayout";
import MainLayout from "layouts/MainLayout";

import AccountOverview from "views/AccountOverview";
import EditProfile from "views/EditProfile";
import PlanSummary from "views/PlanSummary";
import ManageSubscription from "views/ManageSubscription";

import Login from "views/Login";
import SignUp from "views/SignUp";
import NotFound from "views/NotFound";
import ForgetPassword from "views/ForgetPassword";
import Setup from "views/Setup";
import ChoosePlan from "views/ChoosePlan";

import { AnimatedSwitch } from "react-router-transition";
import { ContextProviderComposer } from "components/ContextProviderComposer";
import SnackBarProvider from "components/SnackbarProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFns from "@date-io/date-fns";
import { BUCKET_URL } from "config";

const browserHistory = createBrowserHistory();
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:287e1eeb-91aa-464a-a709-409898f019dd", //REQUIRED - Amazon Cognito Identity Pool ID
    region: "us-east-1", // REQUIRED - Amazon Cognito Region
  },
  Storage: {
    AWSS3: {
      bucket: BUCKET_URL, //REQUIRED -  Amazon S3 bucket
      region: "us-east-2", //OPTIONAL -  Amazon service region
    },
  },
});

function mapStyles(styles: any) {
  return {
    opacity: styles.opacity,
    transform: `translateX(${styles.translateX}px)`,
  };
}

const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    translateX: 400,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: 0,
    translateX: -400,
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: 1,
    translateX: 0,
  },
};

const App = () => {
  return (
    <ContextProviderComposer
      contextProviders={[
        <ThemeProvider theme={theme} key="theme" children="" />,
        <SnackBarProvider key="snackbar" />,
        <MuiPickersUtilsProvider key="df" utils={DateFns} children={<></>} />,
        <DialogProvider key="dialog" children=""></DialogProvider>,
      ]}>
      <CssBaseline />
      <Router history={browserHistory}>
        <Switch>
          <Redirect exact from="/" to="/account-overview" />
          <Route
            path={[
              "/account-overview",
              "/profile",
              "/plan-summary",
              "/subscription",
            ]}>
            <MainLayout>
              <Switch>
                <GuardedRoute
                  path="/account-overview"
                  component={AccountOverview}
                />
                <GuardedRoute path="/profile" component={EditProfile} />
                <GuardedRoute path="/plan-summary" component={PlanSummary} />
                <GuardedRoute
                  path="/subscription"
                  component={ManageSubscription}
                />
              </Switch>
            </MainLayout>
          </Route>
          <Route
            path={[
              "/login",
              "/signup",
              "/setup",
              "/choose-plan",
              "/forget-password",
            ]}>
            <SingleLayout>
              <AnimatedSwitch
                atEnter={bounceTransition.atEnter}
                atLeave={bounceTransition.atLeave}
                atActive={bounceTransition.atActive}
                mapStyles={mapStyles}
                className="switch-wrapper">
                <GuardedRoute path="/login" component={Login} unguarded />
                <GuardedRoute path="/signup" component={SignUp} unguarded />
                <GuardedRoute path="/setup" component={Setup} unguarded />
                <GuardedRoute path="/choose-plan" component={ChoosePlan} />
                <GuardedRoute
                  path="/forget-password"
                  component={ForgetPassword}
                  unguarded
                />
              </AnimatedSwitch>
            </SingleLayout>
          </Route>
          <GuardedRoute path="/not-found" component={NotFound} unguarded />
          <Redirect to="/not-found" />
        </Switch>
      </Router>
    </ContextProviderComposer>
  );
};

export default App;
