import Http from "./Http";
import { get, assign } from "lodash-es";
import { User } from "interfaces/user.interface";
import { Gender } from "config/enum";
import { appStore } from "models";
import { getUpdateUser } from "helpers/calculate";

export type LoginInput = {
  email?: string;
  password?: string;
  googleToken?: string;
};

export const login = ({ email, password, googleToken }: LoginInput) => {
  return Http.post("/login", {
    email,
    password,
    googleToken,
  }).then(resp => {
    const data = get(resp, "data", {});
    if (!data.token) {
      return Promise.resolve(resp);
    }
    return Promise.resolve({
      ...data,
    });
  });
};

type MagicLink = {
  email: string;
};

export const emailMagicLink = ({ email }: MagicLink) => {
  return Http.post("/forgotpassword", {
    email,
  });
};

type FBOptions = { accessToken: string; userID: string };

export const FBLogin = ({ accessToken, userID }: FBOptions) => {
  return Http.post("/user/third-platform", {
    platform: 1,
    socialToken: accessToken,
    platformUserId: userID,
    deviceId: "web",
  }).then(resp => {
    const data = get(resp, "data", {});
    if (!data.token) {
      return Promise.resolve(resp);
    }
    return Promise.resolve({
      ...data,
    });
  });
};

export type SignUpInput = {
  user?: User;
  email: string;
  googleToken?: string;
  name?: string;
  password?: string;
  gender?: number;
};

export const createAccount = ({
  user,
  email,
  googleToken,
  name,
  gender = Gender.FEMALE,
  password,
}: SignUpInput): Promise<User> => {
  return Http.post("createAccount", {
    gender,
    ...user,
    ...(email && { email }),
    ...(googleToken && { googleToken }),
    ...(name && { name }),
    ...(password && { password }),
  }).then(resp => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data as User);
    } else {
      return Promise.reject(resp);
    }
  });
};

export const checkSignUp = (email: string) => {
  return Http.post("check-sign-up", {
    email,
  }).then(resp => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data as User);
    } else {
      return Promise.reject(resp);
    }
  });
};

export const getUserInfo = (userId: string): Promise<User> => {
  return Http.get("user-info", {
    params: { id: userId },
  }).then(resp => {
    if (resp.status === 200) {
      return Promise.resolve(resp.data as User);
    } else {
      return Promise.reject(resp);
    }
  });
};

export const updateUserInfo = (user: User, updateOnBoarding?: boolean) => {
  const delta = getUpdateUser(appStore.currentUser, user, updateOnBoarding);

  return Http.post("user-info", {
    ...delta,
  }).then(resp => {
    if (resp.status === 200) {
      const user = resp.data as User;
      if (user.id === appStore.currentUser.id) {
        appStore.setCurrentUser(assign(appStore.currentUser, resp.data), true);
      }
      return Promise.resolve(resp.data as User);
    } else {
      return Promise.reject(resp);
    }
  });
};

export const deleteAccount = () => {
  return Http.delete("web/user").then(resp => {
    if (resp.status === 200) {
      console.log("resp.data", resp.data);
      return Promise.resolve(resp.data as User);
    } else {
      return Promise.reject(resp);
    }
  });
};
