/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import IconClose from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { appStore } from "models";
import PaymentMethodForm from "./PaymentMethodForm";
import { createStripeCustomer } from "api/Checkout";
import { appInitGetUserInfo } from "models/actions";

const UpdatePaymentMethod = ({ handleCancel, handleConfirm }) => {
  const handleSubmitPaymentMethod = async paymentMethod => {
    await createStripeCustomer(paymentMethod.id);
    await appInitGetUserInfo(appStore.currentUser.id, true);
    handleConfirm();
  };

  return (
    <div className="checkout-modal-content">
      <IconButton className="btn-close-modal" onClick={handleCancel}>
        <IconClose></IconClose>
      </IconButton>
      <div className="modal-title">Update Payment Method</div>
      <Elements
        stripe={loadStripe("pk_test_godWMLo8LygAopI9YbTiS1Jo00cXGaEUPw")}>
        <PaymentMethodForm
          handleSubmitPaymentMethod={handleSubmitPaymentMethod}
          buttonText="update"
        />
      </Elements>
    </div>
  );
};

export default UpdatePaymentMethod;
