export const API_VERSION = "1.0.0";
export const TERMS_PRIVACY = "https://itrackbites.com/terms/";
export const PRIVACY = "https://itrackbites.com/privacy/";
export const HELP_CENTER_URL = "https://help.itrackbites.com";

export const PRODUCT_ID_DEFAULT = "com.ellis.itrackbites.yearly.36";
export const PRODUCT_ID_YEARLY_36 = "com.ellis.itrackbites.yearly.36_v1";
export const PRODUCT_ID_YEARLY_60 = "com.ellis.itrackbites.yearly.60_v1";
export const PRODUCT_ID_YEARLY_12 =
  "com.ellis.itrackbites.yearly.grandfathered.12";
export const PRODUCT_ID_BACKUP_SYNC = "com.ellis.itrackbites.backupsync";
export const PRODUCT_ID_ALL_GUIDES = "com.ellis.itrackbites.allfoodguides";
export const PRODUCT_ID_ALL_GUIDES_NEW =
  "com.ellis.itrackbites.allfoodguidesnew";
export const PRODUCT_ID_RESTAURANT = "com.ellis.itrackbites.restaurantguide";
export const PRODUCT_ID_SNACK = "com.ellis.itrackbites.snackguide";
export const PRODUCT_ID_BEER = "com.ellis.itrackbites.beerguide";
export const PRODUCT_ID_ONLINE = "com.ellis.itrackbites.onlineguide";
export const PRODUCT_ID_ONLINE_UPGRADE =
  "com.ellis.itrackbites.onlineguideupgrade";
export const PRODUCT_ID_RECIPE_BUILDER = "com.ellis.itrackbites.recipebuilder";

export const S3_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://s3.us-east-2.amazonaws.com/prod.itrackbites.com/"
    : "https://s3.us-east-2.amazonaws.com/static.itrackbites.com/";
export const BUCKET_URL =
  process.env.REACT_APP_ENV === "production"
    ? "prod.itrackbites.com"
    : "static.itrackbites.com";
