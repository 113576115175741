import React from "react";
import { Theme, makeStyles, Button } from "@material-ui/core";
import clsx from "clsx";
import Check from "icons/planCheck";
import Close from "icons/planClose";
import { getPriceString, getOriginPriceString } from "helpers/format";
import { appStore } from "models";
import { observer } from "mobx-react";
import { useCreateSubscription } from "models/actions";

interface Props {
  handleNext: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 484,
    height: 381,
    marginTop: 21,
    display: "flex",
    flexFlow: "row nowrap",
    alignItems: "center",
  },
  cardContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
  },
}));

const features = [
  "Food & Activity Tracking",
  "Macronutrient Tracking",
  "Create & Browse Recipes",
  "1M+ Foods & Brands",
  "Premium Weight Loss Plans",
  "Fitbit Activity Tracking",
];

const proFeatures = [0, 1, 2, 3, 4, 5];
const freeFeatures = [0];

const SelectPlan: React.FC<Props> = ({ handleNext }) => {
  const classes = useStyles();
  const currentUser = appStore.currentUser;
  const handleCreateSubscription = useCreateSubscription();
  const handleSelectProPlan = async () => {
    if (!currentUser.isProUser) {
      const result = await handleCreateSubscription();
      if (result) {
        handleNext();
      }
    } else {
      handleNext();
    }
  };
  return (
    <div className={clsx(classes.root, "select-plan")}>
      <div className={clsx("plan-pro", classes.cardContainer)}>
        <div className="plan-badge">PRO</div>
        <div className="plan-price">{getPriceString(appStore.stripePlan)}</div>
        <div className="plan-origin-price">
          {getOriginPriceString(appStore.stripePlan)}
        </div>
        <div className="plan-divider"></div>
        <div className="plan-features">
          {features.map((label, index) => (
            <div className="plan-features-items" key={label}>
              <div>{label}</div>
              {proFeatures.indexOf(index) > -1 ? (
                <Check className="icon-check"></Check>
              ) : (
                <Close className="icon-close"></Close>
              )}
            </div>
          ))}
        </div>
        <Button
          type="button"
          variant="contained"
          color="primary"
          className="plan-select-button"
          onClick={handleSelectProPlan}>
          SELECT
        </Button>
      </div>
      <div className={clsx("plan-free", classes.cardContainer)}>
        <div className="plan-badge">Basic</div>
        <div className="plan-price">Free</div>
        <div className="plan-divider"></div>
        <div className="plan-features">
          {features.map((label, index) => (
            <div className="plan-features-items" key={label}>
              <div>{label}</div>
              {freeFeatures.indexOf(index) > -1 ? (
                <Check className="icon-check"></Check>
              ) : (
                <Close className="icon-close"></Close>
              )}
            </div>
          ))}
        </div>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          className="plan-select-button"
          onClick={handleNext}>
          SELECT
        </Button>
      </div>
    </div>
  );
};

export default observer(SelectPlan);
