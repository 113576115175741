import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function ProBadge(props) {
  return (
    <SvgIcon
      width="44"
      height="24"
      viewBox="0 0 44 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect width="44" height="24" rx="4" fill="#FE5685" />
      <path
        d="M7 6.26613H10.8053C11.3358 6.26613 11.8374 6.31541 12.31 6.41398C12.7827 6.51254 13.1926 6.68504 13.5399 6.93145C13.8968 7.16801 14.1765 7.49328 14.3791 7.90726C14.5913 8.31138 14.6974 8.82392 14.6974 9.44489C14.6974 10.056 14.6009 10.5685 14.408 10.9825C14.2151 11.3965 13.9498 11.7267 13.6122 11.9731C13.2746 12.2195 12.8743 12.397 12.4113 12.5054C11.9483 12.6039 11.4467 12.6532 10.9066 12.6532H9.25712V16.7339H7V6.26613ZM9.25712 10.7016H10.7619C10.9644 10.7016 11.1574 10.6819 11.3406 10.6425C11.5335 10.603 11.7023 10.539 11.847 10.4503C12.0014 10.3517 12.1219 10.2236 12.2087 10.0659C12.3052 9.8983 12.3534 9.69131 12.3534 9.44489C12.3534 9.17876 12.2907 8.96685 12.1653 8.80914C12.0496 8.64158 11.8953 8.51344 11.7023 8.42473C11.5191 8.33602 11.3117 8.28181 11.0802 8.2621C10.8487 8.23253 10.6268 8.21774 10.4146 8.21774H9.25712V10.7016Z"
        fill="white"
      />
      <path
        d="M16.1136 6.26613H20.078C20.5989 6.26613 21.0909 6.32034 21.5539 6.42876C22.0265 6.52733 22.4365 6.69982 22.7837 6.94624C23.1406 7.1828 23.4203 7.50806 23.6229 7.92204C23.8351 8.32617 23.9412 8.83378 23.9412 9.44489C23.9412 10.1841 23.7531 10.815 23.3769 11.3374C23.0007 11.8499 22.4509 12.1752 21.7275 12.3132L24.3319 16.7339H21.6262L19.4848 12.5497H18.3707V16.7339H16.1136V6.26613ZM18.3707 10.5981H19.7019C19.9044 10.5981 20.1166 10.5932 20.3385 10.5833C20.57 10.5636 20.7774 10.5193 20.9606 10.4503C21.1439 10.3714 21.2934 10.2531 21.4092 10.0954C21.5346 9.93772 21.5973 9.71595 21.5973 9.43011C21.5973 9.16398 21.5442 8.95206 21.4381 8.79436C21.332 8.63665 21.197 8.51837 21.033 8.43952C20.869 8.35081 20.6809 8.29167 20.4687 8.2621C20.2565 8.23253 20.0491 8.21774 19.8465 8.21774H18.3707V10.5981Z"
        fill="white"
      />
      <path
        d="M24.9459 11.5C24.9459 10.6622 25.0809 9.90323 25.351 9.22312C25.6307 8.54301 26.0166 7.9664 26.5085 7.49328C27.0004 7.0103 27.584 6.64068 28.2592 6.38441C28.9344 6.12814 29.6723 6 30.4729 6C31.2735 6 32.0114 6.12814 32.6867 6.38441C33.3619 6.64068 33.9454 7.0103 34.4374 7.49328C34.9293 7.9664 35.3103 8.54301 35.5804 9.22312C35.8601 9.90323 36 10.6622 36 11.5C36 12.3378 35.8601 13.0968 35.5804 13.7769C35.3103 14.457 34.9293 15.0385 34.4374 15.5215C33.9454 15.9946 33.3619 16.3593 32.6867 16.6156C32.0114 16.8719 31.2735 17 30.4729 17C29.6723 17 28.9344 16.8719 28.2592 16.6156C27.584 16.3593 27.0004 15.9946 26.5085 15.5215C26.0166 15.0385 25.6307 14.457 25.351 13.7769C25.0809 13.0968 24.9459 12.3378 24.9459 11.5ZM27.2898 11.5C27.2898 11.9928 27.3622 12.4462 27.5068 12.8602C27.6612 13.2643 27.8782 13.6192 28.1579 13.9247C28.4377 14.2204 28.7704 14.4521 29.1563 14.6196C29.5518 14.7872 29.9906 14.871 30.4729 14.871C30.9552 14.871 31.3893 14.7872 31.7751 14.6196C32.1706 14.4521 32.5082 14.2204 32.7879 13.9247C33.0677 13.6192 33.2799 13.2643 33.4246 12.8602C33.5789 12.4462 33.6561 11.9928 33.6561 11.5C33.6561 11.017 33.5789 10.5685 33.4246 10.1546C33.2799 9.74059 33.0677 9.38575 32.7879 9.09005C32.5082 8.7845 32.1706 8.54794 31.7751 8.38038C31.3893 8.21281 30.9552 8.12903 30.4729 8.12903C29.9906 8.12903 29.5518 8.21281 29.1563 8.38038C28.7704 8.54794 28.4377 8.7845 28.1579 9.09005C27.8782 9.38575 27.6612 9.74059 27.5068 10.1546C27.3622 10.5685 27.2898 11.017 27.2898 11.5Z"
        fill="white"
      />
    </SvgIcon>
  );
}
