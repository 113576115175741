import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import "./index.scss";
import { appStore } from "models";
import { getPlan } from "config/plans";
import LoadingButton from "components/LoadingButton";
import { NavLink, useHistory } from "react-router-dom";
import { get } from "lodash-es";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { LossPlan } from "config/enum";
import { inchesToString } from "helpers/calculate";
import { toFixed } from "helpers/math";
import { observer } from "mobx-react";
import { format } from "date-fns";

interface Props {}

const PhoneNumberSchema = yup.object().shape({
  phoneNumber: yup.string().required(),
});

const getUnitForPlan = (lossPlan: number) => {
  if (lossPlan === LossPlan.KEEPING_KETO) {
    return "NET CARBS";
  } else if (lossPlan === LossPlan.CALORIE_COMMAND) {
    return "calories";
  }
  return "bites";
};

const dailyAllowanceForPlan = (
  dailyAllowance: number,
  lossPlan: number,
  isUseDecimals?: boolean,
) => {
  if (lossPlan === LossPlan.CALORIE_COMMAND) {
    return String(toFixed(dailyAllowance, 0));
  } else if (lossPlan === LossPlan.KEEPING_KETO) {
    return String(toFixed(dailyAllowance, 1));
  } else {
    if (isUseDecimals) {
      return String(toFixed(dailyAllowance, 1));
    } else {
      return String(toFixed(dailyAllowance, 0));
    }
  }
};

const AccountOverview: React.FC<Props> = () => {
  const currentUser = appStore.currentUser;
  const plan = getPlan(currentUser.lossPlan);
  const isPro = currentUser.isProUser;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    validationSchema: PhoneNumberSchema,
  });

  const handleTextMeTheApp = (data: any) => {
    setLoading(true);
    fetch("https://bnc.lt/c/zwbeKBcEx4", {
      credentials: "omit",
      headers: {
        accept: "*/*",
        "accept-language": "zh-CN,zh;q=0.9,en;q=0.8",
        "cache-control": "no-cache",
        "content-type": "application/x-www-form-urlencoded",
        pragma: "no-cache",
        "sec-fetch-dest": "empty",
        "sec-fetch-mode": "cors",
        "sec-fetch-site": "cross-site",
      },
      referrer: "https://itrackbites.com/",
      referrerPolicy: "no-referrer-when-downgrade",
      body: `sdk=web2.52.5&phone=${data.phoneNumber}&branch_key=key_live_lcJSoZz778AlVNGFUUxS7oknvDkIEW0a`,
      method: "POST",
      mode: "cors",
    }).then(() => {
      setLoading(false);
    });
  };
  const dailyAllowance = dailyAllowanceForPlan(
    currentUser.dailyAllowance,
    currentUser.lossPlan,
    currentUser.isUseDecimals,
  );

  return (
    <Grid className="page-account-view" spacing={6} container>
      <Grid xs={6} item>
        <Box className="content-box profile-box">
          <div className="box-sub-title">PROFILE</div>
          <div className="profile-info-row">
            <i className="profile-icon icon-mail"></i>
            {currentUser.email}
          </div>
          <div className="profile-info-row">
            <i className="profile-icon icon-cake"></i>
            {format(new Date(currentUser.birthDate), "MM/dd/yyyy")}
          </div>
          <div className="profile-info-row">
            <i className="profile-icon icon-height"></i>
            {inchesToString(currentUser.heightInch, currentUser.heightUnit)}
          </div>
          <NavLink to="/profile">
            <div className="content-box-link">Edit Profile &gt;</div>
          </NavLink>
        </Box>
      </Grid>
      <Grid xs={6} item>
        <Box className={`content-box membership-box ${isPro ? "pro" : "free"}`}>
          <div className="box-sub-title">MEMBERSHIP</div>
          <Typography variant="h2" className="membership-title">
            {isPro ? "Pro" : "Basic"}
          </Typography>
          {isPro ? (
            <div className="pro-description">
              You’re ready to supercharge your weight loss with our most popular
              Pro features.
            </div>
          ) : (
            <div className="change-to-pro">
              <Typography className="change-to-pro-description">
                <strong>Supercharge</strong> your weight loss with unlimited
                access to our most popular <strong>Pro features</strong>.
              </Typography>
              <Button
                variant="text"
                className="btn-go-pro"
                onClick={() => {
                  history.push("/subscription");
                }}>
                EXPLORE pro
              </Button>
            </div>
          )}
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box className={`content-box plan-box ${plan.className}`}>
          <div className="plan-bg plan-box-bg-position"></div>
          <div className="plan-box-left">
            <div className="box-sub-title">Your PLAN</div>
            <div className="plan-info-row">
              <Typography variant="h2" className="plan-title">
                {plan.title}
              </Typography>
              <div className="plan-icon plan-icon plan-icon-bg" />
            </div>
            <div className="plan-description">{plan.description}</div>
            <NavLink to="/plan-summary">
              <div className="content-box-link btn-change-plan">
                Change Your Plan &gt;
              </div>
            </NavLink>
          </div>
          <div className="plan-box-right">
            <div className="plan-daily-allowance">
              <div className="box-sub-title">Daily Allowance</div>
              <div className="plan-daily-allowance-chart">
                <CircularProgress
                  variant="static"
                  size={130}
                  thickness={3}
                  className="plan-daily-allowance-progress"
                  value={50}
                />
                <div className="plan-daily-allowance-number">
                  {dailyAllowance}
                </div>
              </div>
              <div className="plan-daily-allowance-description">
                {`Your daily allowance is ${dailyAllowance} ${getUnitForPlan(
                  currentUser.lossPlan,
                )}.`}
              </div>
            </div>
          </div>
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box className="content-box get-mobile-app-box">
          <div className="box-sub-title">MOBILE APP</div>
          <div className="submit-your-phone-number-description">
            Submit your phone number for a direct link to download the app on
            your phone.
          </div>
          <div className="submit-phone-number-form">
            <TextField
              inputRef={register}
              error={!!errors.phoneNumber}
              helperText={get(errors, "phoneNumber.message")}
              className="phone-number-input"
              variant="outlined"
              margin="normal"
              fullWidth
              id="phone"
              type="phone"
              placeholder="+1 (000) 000-0000"
              name="phoneNumber"
              autoComplete="phone"
            />
            <LoadingButton
              loading={loading}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleSubmit(handleTextMeTheApp)}
              className="btn-submit-phone-number">
              text me the app
            </LoadingButton>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default observer(AccountOverview);
