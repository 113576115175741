import React from "react";
import { Box, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import Male from "icons/male";
import Female from "icons/female";
import clsx from "clsx";
import { Gender } from "config/enum";
import MySelect from "components/MySelect";
import { map } from "lodash-es";

interface Props {
  value: number;
  onChange: (gender: number) => number;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 48,
    cursor: "pointer",
    width: 327,
  },
  bigButton: {
    width: 140,
    height: 110,
    background: "#F7F8FA",
    borderRadius: 16,
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiTypography-root": {
      color: "#959FAF",
      marginTop: 20,
    },
    "& .MuiSvgIcon-root": {
      marginTop: 10,
    },
  },
  active: {
    background: theme.palette.secondary.light,
    "& .MuiTypography-root": {
      color: "white",
    },
    "& .MuiSvgIcon-root path": {
      stroke: "white",
    },
  },
  chooseNursing: {
    width: 327,
    height: 93,
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-formControl": {
      backgroundColor: "white",
      marginTop: 20,
      "& .MuiSelect-select": {
        backgroundColor: "white",
        paddingLeft: "2px!important",
        borderRadius: 0,
        borderBottom: "1px solid rgba(50, 53, 85, 0.2)",
      },
    },
  },
}));

const genderNursingOptions = [
  { text: "No", value: Gender.FEMALE },
  { text: "Full-Time Nursing", value: Gender.NURSING_FULL_TIME },
  { text: "Part-Time Nursing", value: Gender.NURSING_PART_TIME },
];

const SelectGender: React.FC<Props> = ({ value, onChange }) => {
  const classes = useStyles();
  const isChooseFemale =
    [Gender.FEMALE, Gender.NURSING_FULL_TIME, Gender.NURSING_PART_TIME].indexOf(
      value,
    ) > -1;
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justify="center"
      spacing={5}
      className={classes.root}>
      <Grid item xs={6}>
        <Box
          className={clsx(classes.bigButton, {
            [classes.active]: value === Gender.MALE,
          })}
          onClick={() => onChange(Gender.MALE)}>
          <Male></Male>
          <Typography variant="button">MALE</Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          className={clsx(classes.bigButton, {
            [classes.active]: isChooseFemale,
          })}
          onClick={() => onChange(Gender.FEMALE)}>
          <Female></Female>
          <Typography variant="button">FEMALE</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.chooseNursing}>
        {isChooseFemale && (
          <MySelect
            name="gender"
            label="Are you nursing?"
            value={value}
            options={genderNursingOptions}
            onChange={event => onChange(event.target.value as number)}
            fullWidth></MySelect>
        )}
      </Grid>
    </Grid>
  );
};

export default SelectGender;
