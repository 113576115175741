import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function EyeClose(props) {
  return (
    <SvgIcon
      viewBox="-4 -4 24 24"
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g opacity="0.3">
        <path
          d="M14.5999 5.59998L6.3999 13.8C6.8999 13.9 7.4999 14 7.9999 14C11.5999 14 14.3999 10.9 15.5999 9.09998C16.0999 8.39998 16.0999 7.49998 15.5999 6.79998C15.3999 6.49998 14.9999 6.09998 14.5999 5.59998Z"
          fill="#434343"
        />
        <path
          d="M14.3 0.3L11.6 3C10.5 2.4 9.3 2 8 2C4.4 2 1.6 5.1 0.4 6.9C-0.1 7.6 -0.1 8.5 0.4 9.1C0.9 9.9 1.8 10.9 2.8 11.8L0.3 14.3C-0.1 14.7 -0.1 15.3 0.3 15.7C0.5 15.9 0.7 16 1 16C1.3 16 1.5 15.9 1.7 15.7L15.7 1.7C16.1 1.3 16.1 0.7 15.7 0.3C15.3 -0.1 14.7 -0.1 14.3 0.3ZM5.3 9.3C5.1 8.9 5 8.5 5 8C5 6.3 6.3 5 8 5C8.5 5 8.9 5.1 9.3 5.3L5.3 9.3Z"
          fill="#434343"
        />
      </g>
    </SvgIcon>
  );
}
