import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { map } from "lodash-es";

type Props = {
  options?: { text: string | number; value: any }[];
  error?: boolean;
  helperText?: string;
  className?: string;
  label?: string;
  anchor: any;
  onChange?: any;
  onValueChange?: any;
  name?: string;
  value?: any;
};

const SimpleMenu: React.FC<Props> = props => {
  const { options, anchor: Anchor, onChange, value, onValueChange } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = value => () => {
    onChange(value);
    onValueChange && onValueChange(value);
    handleClose();
  };

  return (
    <div>
      <div className="memu-trigger-container" onClick={handleClick}>
        <Anchor value={value}></Anchor>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {map(options, ({ text, value }) => (
          <MenuItem
            selected={value === props.value}
            key={value}
            onClick={handleOptionClick(value)}>
            {text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SimpleMenu;
