import { some, isEmpty, get } from "lodash-es";

export const shouldDisabledButton = (
  watch: any,
  errors: any,
  fields?: string[],
) => {
  const values = watch();

  const hasEmptyFields = fields
    ? some(fields, (key: string) => isEmptyField(get(values, key, "")))
    : some(values, value => isEmptyField(value));
  const hasErrors = fields
    ? some(fields, (key: string) => errors[key])
    : !isEmpty(errors);
  return hasEmptyFields || hasErrors;
};

const isEmptyField = (field: any) => {
  return field === undefined || field === null || field.length < 1;
};
