/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CustomModalComponentProps } from "components/Dialog/DialogProvider";
import IconClose from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import ProBadge from "icons/pro-badge";
import { observer } from "mobx-react";
import { appStore } from "models";
import { getPriceString, getOriginPriceString } from "helpers/format";
import PaymentMethodForm from "./PaymentMethodForm";
import { createStripeCustomer, createSubscription } from "api/Checkout";
import { appInitGetUserInfo } from "models/actions";

import "./index.scss";

interface Props extends CustomModalComponentProps {
  lossPlan?: number;
}

const CheckoutModal: React.FC<Props> = ({ handleCancel, handleConfirm }) => {
  const handleSubmitPaymentMethod = async paymentMethod => {
    await createStripeCustomer(paymentMethod.id);
    await createSubscription(appStore.stripePlan.id);
    await appInitGetUserInfo(appStore.currentUser.id, true);
    handleConfirm();
  };

  return (
    <div className="checkout-modal-content">
      <IconButton className="btn-close-modal" onClick={handleCancel}>
        <IconClose></IconClose>
      </IconButton>
      <div className="modal-title">Checkout</div>
      <div className="modal-info-block your-plan">
        <div className="modal-info-block-title">Your plan</div>
        <div className="your-plan-box">
          <div className="plan-title">{appStore.stripePlan.nickname}</div>
          <div className="plan-price">
            {getPriceString(appStore.stripePlan)}
            <div className="plan-origin-price">
              {getOriginPriceString(appStore.stripePlan)}
            </div>
          </div>
          <ProBadge className="plan-bage-pro" />
        </div>
      </div>

      <Elements
        stripe={loadStripe("pk_test_godWMLo8LygAopI9YbTiS1Jo00cXGaEUPw")}>
        <PaymentMethodForm
          handleSubmitPaymentMethod={handleSubmitPaymentMethod}
          buttonText="checkout"
        />
      </Elements>
    </div>
  );
};

export default observer(CheckoutModal);
