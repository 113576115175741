import React from "react";
import { Badge, Avatar, Divider, MenuList, MenuItem } from "@material-ui/core";
import "./index.scss";
import { appStore } from "models";
import { observer } from "mobx-react";
import { NavLink, useLocation } from "react-router-dom";
import { getPlan } from "config/plans";
import { get } from "lodash-es";
import ProBadge from "icons/pro-badge";
import BasicBadge from "icons/basic-badge";

interface Props {}

const navs = [
  { text: "Account Overview", url: "/account-overview" },
  { text: "Edit Profile", url: "/profile" },
  { text: "Plan Summary", url: "/plan-summary" },
  { text: "Manage Subscription", url: "/subscription" },
];

const LeftNav: React.FC<Props> = () => {
  const location = useLocation();
  const user = appStore.currentUser;
  const plan = getPlan(user.lossPlan);
  return (
    <div className="left-nav content-box">
      {user.isProUser ? (
        <ProBadge className="user-sub-badge" />
      ) : (
        <BasicBadge className="user-sub-badge" />
      )}
      <div className={`user-info ${get(plan, "className")}`}>
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={
            <div className="avatar-plan-icon plan-icon plan-icon-bg" />
          }>
          <Avatar
            alt={user.name}
            className="user-avatar"
            src={user.profilePhotoUrl}>
            <img
              src="/images/default-avatar.svg"
              className="user-avatar-fallback"
              alt=""
            />
          </Avatar>
        </Badge>
        <div className="user-name">{user.name}</div>
        <div className="user-username">{user.username}</div>
      </div>
      <Divider className="divider"></Divider>
      <MenuList className="nav-list">
        {navs.map(({ text, url }) => (
          <NavLink key={url} to={url}>
            <MenuItem
              className="nav-link"
              selected={location.pathname.indexOf(url) > -1}>
              {text}
            </MenuItem>
          </NavLink>
        ))}
      </MenuList>
    </div>
  );
};

export default observer(LeftNav);
