import Appboy from "appboy-web-sdk";
import Amplitude from "amplitude-js";
import { APPBOY_WEB_API_KEY, AMPLITUDE_API_KEY } from "config/appkey";
import { User } from "interfaces/user.interface";
import {
  Gender,
  GenderNames,
  LossPlanNames,
  ActivityLevelNames,
  FitnessGoalBitesNames,
  WeightUnit,
  FitnessGoalCaloriesKgNames,
  FitnessGoalCaloriesLbsNames,
  ActivityAllowanceStrs,
  SecondaryMetricStrsAll,
  ExtraAllowanceStrs,
} from "config/enum";
import { isBitesPlan } from "config/plans";
import { getYear, getMonth, getDate } from "date-fns";

Amplitude.getInstance().init(AMPLITUDE_API_KEY);

Appboy.initialize(APPBOY_WEB_API_KEY, { baseUrl: "sdk.iad-03.braze.com" });
Appboy.display.automaticallyShowNewInAppMessages();
Appboy.openSession();

export const trackUserProfile = (user: User) => {
  Amplitude.getInstance().setUserId(user.id);
  Appboy.changeUser(user.id);
  const abUser = Appboy.getUser();
  (window as any).Appboy = Appboy;
  (window as any).abUser = abUser;
  const identify = new Amplitude.Identify();

  identify.set("First Name", user.firstName);
  identify.set("Last Name", user.lastName);
  identify.set("Email", user.email);
  identify.set("Date of Birth", user.birthDate);
  identify.set("Gender", GenderNames[user.gender]);

  abUser.setFirstName(user.firstName);
  abUser.setLastName(user.lastName);
  abUser.setEmail(user.email);
  const birthDate = new Date(user.birthDate);
  abUser.setDateOfBirth(
    getYear(birthDate),
    getMonth(birthDate) + 1,
    getDate(birthDate),
  );
  abUser.setGender(user.gender === Gender.MALE ? "m" : "f");

  abUser.setCustomUserAttribute("Point System", LossPlanNames[user.lossPlan]);
  abUser.setCustomUserAttribute(
    "Activity Level",
    ActivityLevelNames[user.activityLevel],
  );

  abUser.setCustomUserAttribute("Start Weight", user.startWeightLbs);
  abUser.setCustomUserAttribute("Goal Weight", user.goalWeightLbs);

  abUser.setCustomUserAttribute("Weekly Weigh-in Day", user.weekStartDay);

  identify.set("Point System", LossPlanNames[user.lossPlan]);
  identify.set("Activity Level", ActivityLevelNames[user.activityLevel]);
  identify.set("Start Weight", user.startWeightLbs);
  identify.set("Height", user.heightInch);
  identify.set("Goal Weight", user.goalWeightLbs);
  identify.set("Manual Allowance", user.isManualAllowance);

  identify.set("Weight Lost", user.recentWeight - user.startWeightLbs);
  identify.set("Weight Remaining", user.recentWeight - user.goalWeightLbs);

  let fitnessGoal = "";
  if (isBitesPlan(user.lossPlan)) {
    fitnessGoal = FitnessGoalBitesNames[user.fitnessGoal];
  } else {
    if (user.weightUnit === WeightUnit.KILOGRAMS) {
      fitnessGoal = FitnessGoalCaloriesKgNames[user.fitnessGoal];
    } else {
      fitnessGoal = FitnessGoalCaloriesLbsNames[user.fitnessGoal];
    }
  }

  identify.set("Fitness Goal", fitnessGoal);

  const weeksLbl = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  identify.set("Next Week Starts", weeksLbl[user.weekStartDay]);

  identify.set("Extra Allowance", ExtraAllowanceStrs[user.extraAllowanceOrder]);
  identify.set(
    "Activity Allowance",
    ActivityAllowanceStrs[user.atyAllowanceMethod],
  );
  identify.set(
    "Secondary Metric",
    SecondaryMetricStrsAll[user.secondaryMetric],
  );
  identify.set("App Icon Badge", user.isShowIconBadge);
  identify.set("Use Decimals", user.isUseDecimals);
  identify.set("Show Progress", user.isShowWeightProgress);

  identify.set("Account Created Date", user.dateCreated);

  if (user.subscriptionExpirationDate) {
    identify.set(
      "Subscription Expiration Date",
      user.subscriptionExpirationDate,
    );
  }
  identify.set("Purchased App", user.isPurchasedApp);
  abUser.setCustomUserAttribute("Purchased App", user.isPurchasedApp);

  identify.set("Subscriber", user.isProUser);
  abUser.setCustomUserAttribute("Purchased Pro", user.isProUser);

  let userType = "";
  if (user.isProUser) {
    userType = "subscriber";
  } else if (user.isPurchasedApp) {
    userType = "paid";
  } else {
    userType = "free";
  }

  identify.set("User Type", userType);
  Amplitude.getInstance()?.identify(identify);

  // Apptentive.shared.personEmailAddress = user.email
  // Apptentive.shared.personName = user.name
  // Apptentive.shared.addCustomPersonData(user.id, withKey: "User ID")
  // Apptentive.shared.addCustomPersonData(userType, withKey: "User Type")
  // Apptentive.shared.addCustomPersonData(user.lossPlan.description(), withKey: "Point System")
  Appboy.requestImmediateDataFlush();
};
