import { toFixed } from "./math";
import { get } from "lodash-es";

// export const FeetInchesFormatter = (str = "") => {
//   const strNumber = str.replace(/[^\d]/g, "");
//   return strNumber[0]
//     ? strNumber[1]
//       ? `${strNumber[0]}'${strNumber.slice(1, 3)}"`
//       : strNumber
//     : "";
// };

export const FeetInchesFormatter = "#' ##\"";

export const StonesFormatter = (num: number) => {
  const numberStr = String(num);
  if (numberStr.length > 0) {
    const reg =
      Number(numberStr[0]) >= 5
        ? /(\d{1})[^\d]*(\d{1,2})?[^\d]*/
        : /(\d{1,2})[^\d]*(\d{1,2})?[^\d]*/;
    const [_, st, lb = ""] = reg.exec(numberStr);
    return `${st} st ${lb} lbs`;
  }
  return "";
};

export const getPriceString = stripePlan => {
  return `$${stripePlan.amount / 100}`;
};

export const getOriginPriceString = stripePlan => {
  const amount = get(stripePlan, "amount");
  const discount = get(stripePlan, "metadata.discount");
  const price = discount
    ? toFixed(Math.ceil((amount / discount) * 100) / 100, 2)
    : stripePlan.amount / 100;
  return `$${price}`;
};
