import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { SelectProps } from "@material-ui/core/Select";
import { map } from "lodash-es";

type Props = MyType.Overwrite<
  SelectProps,
  {
    options: { text: string | number; value: any }[];
    error?: boolean;
    helperText?: string;
    className?: string;
    inputClassName?: string;
    label?: string;
    margin?: "none" | "dense" | "normal";
  }
>;

const MySelect: React.FC<Props> = props => {
  const {
    className,
    label,
    margin,
    inputClassName,
    error,
    helperText,
    options,
    ...otherProps
  } = props;
  return (
    <FormControl
      margin={margin}
      className={className}
      variant="outlined"
      fullWidth={!!props.fullWidth}>
      <InputLabel htmlFor={props.id}>{label}</InputLabel>
      <Select {...otherProps} className={inputClassName} error={error}>
        {/* <MenuItem value={""}></MenuItem> */}
        {map(options, ({ text, value }) => (
          <MenuItem value={value} key={value}>
            {text}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default MySelect;
