import React from "react";
import { Grid, Box } from "@material-ui/core";
import PlanCard from "components/PlanCard/Inline";
import MinimalPlanCard from "components/PlanCard/Minmal";
import "./index.scss";
import { appStore } from "models";
import { lossPlans } from "config/plans";
import useDialog from "components/Dialog/useDialog";
import ConfirmPlanModal from "./ConfirmPlanModal";
import { observer } from "mobx-react";
import { noop } from "lodash-es";

interface Props {}

const PlanSummary: React.FC<Props> = () => {
  const { openDialog } = useDialog();
  const lossPlan = appStore.currentUser.lossPlan || 0;
  const handleChangePlan = (lossPlan: number) => () => {
    openDialog({
      component: ConfirmPlanModal,
      injectProps: { lossPlan },
    }).catch(noop);
  };
  return (
    <Grid className="page-plan-summary" spacing={6} container>
      <Grid xs={12} item>
        <Box className="content-box current-plan-box">
          <div className="box-sub-title">CURRENT PLAN</div>
          <PlanCard lossPlan={lossPlan}></PlanCard>
        </Box>
      </Grid>
      <Grid xs={12} item>
        <Box className="content-box">
          <div className="box-sub-title">
            ADDITIONAL PLANS
            <div className="box-sub-title-description">
              Want a different plan? Select any below.
            </div>
            <div className="addtional-plans">
              {lossPlans.map(plan =>
                plan.lossPlan === lossPlan ? null : (
                  <span
                    className="change-plan"
                    key={plan.lossPlan}
                    onClick={handleChangePlan(plan.lossPlan)}>
                    <MinimalPlanCard lossPlan={plan.lossPlan}></MinimalPlanCard>
                  </span>
                ),
              )}
            </div>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default observer(PlanSummary);
