/* eslint-disable @typescript-eslint/camelcase */
import React from "react";
import LoadingButton from "components/LoadingButton";
import IconClose from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import ProBadge from "icons/pro-badge";
import { observer } from "mobx-react";
import { appStore } from "models";
import { getPriceString, getOriginPriceString } from "helpers/format";
import { format } from "date-fns";
import { get } from "lodash-es";

import "./index.scss";
import IconCheck from "icons/feature/check";

const SubscribeSuccessModal = ({ handleCancel, handleConfirm }) => {
  const currentUser = appStore.currentUser;
  const subscriptionExpirationDate = get(
    currentUser,
    "subscription.subscriptionExpirationDate",
  );
  return (
    <div className="checkout-modal-content">
      <IconButton className="btn-close-modal" onClick={handleCancel}>
        <IconClose></IconClose>
      </IconButton>
      <div className="modal-title">Congratulations!</div>
      <div className="modal-sub-title">
        You have successfully completed your purchase.
      </div>
      <div className="modal-info-block your-plan">
        <div className="your-plan-box">
          <div className="plan-title">{appStore.stripePlan.nickname}</div>
          <div className="plan-price">
            {getPriceString(appStore.stripePlan)}
            <IconCheck className="success-check"></IconCheck>
          </div>
          <div className="plan-expires">
            {`Your subscription is active and your next renewal will be on ${format(
              new Date(subscriptionExpirationDate),
              "MMM do, yyyy",
            )}.`}
          </div>
          <ProBadge className="plan-bage-pro" />
        </div>
      </div>
      <LoadingButton
        className="btn-red btn-checkout"
        type="button"
        variant="contained"
        color="primary"
        onClick={handleConfirm}
        fullWidth>
        Done
      </LoadingButton>
    </div>
  );
};

export default observer(SubscribeSuccessModal);
