import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Avatar,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import "./index.scss";
import { appStore } from "models";
import { useSnackbar } from "notistack";
import { useForm, OnSubmit, Controller } from "react-hook-form";
import { get, find, noop } from "lodash-es";
import LoadingButton from "components/LoadingButton";
import {
  ControledDatePicker,
  NumberFormatWithError,
} from "components/FormHelper";
import AdornmentInput from "components/AdornmentInput";
import { updateUserInfo, deleteAccount } from "api/Auth";
import { Storage } from "aws-amplify";
import { uuidv4 } from "helpers/uuid";
import { S3_URL } from "config";
import { observer } from "mobx-react";
import { shouldDisabledButton } from "helpers/common";
import { heightUnits } from "config/unit";
import { HeightUnit } from "config/enum";
import { FeetInchesFormatter } from "helpers/format";
import MySelect from "components/MySelect";
import { genderOptions } from "./SelectGender";
import * as yup from "yup";
import { validateHeight } from "helpers/validate";
import useDialog from "components/Dialog/useDialog";
import { useHistory } from "react-router-dom";
import ConfirmDeleteAccountDialog from "./ConfirmDeleteAccountDialog";

interface Props {}

const getCurrentWatchRef = () => appStore.currentUser;

const EditProfileSchema = yup.object().shape({
  gender: yup.number().required(),
  birthDate: yup.string().required(),
  name: yup.string().required(),
  username: yup.string(),
  about: yup.string(),
  email: yup
    .string()
    .required()
    .email("Invalid email"),
  height: yup
    .string()
    .required()
    .test(validateHeight(getCurrentWatchRef) as any),
});

const EditProfile: React.FC<Props> = () => {
  const currentUser = appStore.currentUser;
  const [loading, setLoading] = useState(false);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const heightUnitFormat =
    currentUser.heightUnit === HeightUnit.FEET_AND_INCHES
      ? FeetInchesFormatter
      : null;
  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files![0];
    if (file) {
      setAvatarFile(file);
      event.currentTarget.value = "";
    }
  };
  const onRemove = () => {
    setAvatarFile(null);
  };
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setError,
    clearError,
    control,
    formState,
  } = useForm({
    mode: "onBlur",
    validationSchema: EditProfileSchema,
    defaultValues: {
      ...currentUser,
    },
  });
  const { enqueueSnackbar } = useSnackbar();
  const disabled =
    (!formState.dirty && !avatarFile) ||
    shouldDisabledButton(watch, errors, [
      "name",
      "email",
      "gender",
      "birthDate",
      "height",
    ]);

  useEffect(() => {
    setAvatarFile(null);
  }, [currentUser.profilePhotoUrl]);

  const onSubmit: OnSubmit<{}> = async (data: any) => {
    setLoading(true);
    if (avatarFile) {
      const remotePath = "photo/profile/raw/";
      const customPrefix = {
        public: remotePath,
      };
      if (data.profilePhotoUrl) {
        await Storage.remove(data.profilePhotoUrl);
      }
      const result: any = await Storage.put(uuidv4() + ".png", avatarFile, {
        customPrefix: customPrefix,
      })
        .catch(error => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        })
        .finally(() => setLoading(false));
      if (result) {
        data.profilePhotoUrl = S3_URL + remotePath + result.key;
      }
    }
    updateUserInfo(data)
      .then(() => {
        enqueueSnackbar(`Profile updated.`, {
          variant: "success",
        });
      })
      .catch(error => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      })
      .finally(() => setLoading(false));
  };

  const { openDialog } = useDialog();
  const history = useHistory();
  const handleDeleteAccount = () => {
    openDialog({
      subTitle: "delete account",
      title: "We’re sorry to see you go",
      content: <ConfirmDeleteAccountDialog />,
      cancelText: "delete account",
      confirmText: "cancel",
    })
      .then(result => {
        if (!result) {
          appStore.setAppLoading(true);
          deleteAccount()
            .then(() => {
              appStore.logout();
              history.push("/login");
            })
            .finally(() => {
              appStore.setAppLoading(false);
            });
        }
      })
      .catch(noop);
  };

  return (
    <Grid className="page-edit-profile" spacing={6} container>
      <Grid xs={12} item>
        <Box className="content-box edit-profile-box">
          <div className="box-title">Edit Profile</div>
          <form>
            <div className="avatar-area">
              <Avatar
                alt={currentUser.name}
                className="user-avatar"
                src={
                  avatarFile
                    ? URL.createObjectURL(avatarFile)
                    : currentUser.profilePhotoUrl
                }>
                <img
                  src="/images/default-avatar.svg"
                  className="user-avatar-fallback"
                  alt=""
                />
              </Avatar>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="avatarFileInput"
                type="file"
                onChange={onUpload}
              />
              <label htmlFor="avatarFileInput">
                <div className="content-box-link">Change profile picture</div>
              </label>
              {avatarFile && (
                <div className="content-box-link secondary" onClick={onRemove}>
                  Delete
                </div>
              )}
            </div>
            <Grid container spacing={6} className="profile-form">
              <Grid xs={6} item>
                <TextField
                  inputRef={register}
                  variant="outlined"
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  placeholder="Name"
                  error={!!errors.name}
                  helperText={get(errors, "name.message")}
                  autoComplete="name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid xs={6} item>
                <TextField
                  inputRef={register}
                  variant="outlined"
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  placeholder="Username"
                  error={!!errors.username}
                  helperText={get(errors, "username.message")}
                  autoComplete="username"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid xs={12} item>
                <TextField
                  inputRef={register}
                  variant="outlined"
                  fullWidth
                  id="about"
                  label="About"
                  name="about"
                  placeholder="Tell us more about yourself."
                  error={!!errors.about}
                  helperText={get(errors, "about.message")}
                  autoComplete="about"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid xs={6} item>
                <TextField
                  inputRef={register}
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email"
                  placeholder="Email"
                  name="email"
                  error={!!errors.email}
                  helperText={get(errors, "email.message")}
                  autoComplete="email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid xs={6} item>
                <Controller
                  as={MySelect}
                  control={control}
                  options={genderOptions}
                  id="gender"
                  label="gender"
                  name="gender"
                  fullWidth
                />
              </Grid>
              <Grid xs={6} item>
                <Controller
                  as={ControledDatePicker}
                  control={control}
                  name="birthDate"
                  label="birthDate"
                  placeholder="BirthDate"
                  errors={errors}
                  setError={setError}
                  clearError={clearError}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid xs={6} item>
                <Controller
                  as={NumberFormatWithError}
                  name="height"
                  control={control}
                  customInput={AdornmentInput}
                  format={heightUnitFormat}
                  id="height"
                  label="Height"
                  placeholder="Height"
                  endAdornment={
                    <InputAdornment position="end">
                      <div className="">
                        {get(
                          find(heightUnits, { value: currentUser.heightUnit }),
                          "text",
                        )}
                      </div>
                    </InputAdornment>
                  }
                  errors={errors}
                  fullWidth
                  shrink
                />
              </Grid>
            </Grid>
          </form>
          <Grid xs={12} item className="actions-row">
            <Button
              className="btn-delete-account"
              onClick={handleDeleteAccount}>
              Delete account
            </Button>
            <LoadingButton
              className="btn-submit-profile-form"
              loading={loading}
              type="button"
              variant="contained"
              color="primary"
              disabled={disabled}
              onClick={handleSubmit(onSubmit)}>
              Save
            </LoadingButton>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export default observer(EditProfile);
