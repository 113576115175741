import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles, Theme } from "@material-ui/core/styles";
import SimpleCardPage, { useHeaderStyles } from "components/SimpleCradPage";
import { NavLink, useHistory } from "react-router-dom";
import LoadingButton from "components/LoadingButton";
import { useForm } from "react-hook-form";
import * as Auth from "api/Auth";
import { useSnackbar } from "notistack";
import * as yup from "yup";
import { get } from "lodash-es";
import { InputAdornment, IconButton } from "@material-ui/core";
import { setLoginInfo } from "helpers/authstorage";
import AdornmentInput from "components/AdornmentInput";
import { appStore } from "models";
import { onClickGoogleLogin } from "api/GoogleAuth";
import EyeOpen from "icons/eye-open";
import EyeClose from "icons/eye-close";
import { shouldDisabledButton } from "helpers/common";

const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .required()
    .email("Invalid email"),
  password: yup.string().required(),
});

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: "290px",
  },
  login: {
    margin: theme.spacing(2, 0, 1),
  },
  continueWithGoogle: {
    margin: theme.spacing(2, 0, 1),
    paddingLeft: 44,
    textTransform: "none",
  },
  input: {
    margin: "0px 0px 10px 0px",
  },
  googleIcon: {
    backgroundColor: "white",
    height: 44,
    width: 44,
    borderRadius: 2,
    top: "3px",
    left: "3px",
    position: "absolute",
    padding: 7,
  },
  forgetPassword: {
    color: theme.palette.text.hint,
    fontSize: 12,
    fontWeight: 800,
  },
  or: {
    color: theme.palette.text.disabled,
    paddingTop: 8,
  },
  doNotHaveAccount: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const headerClasses = useHeaderStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setError,
    clearError,
    unregister,
  } = useForm({
    mode: "onBlur",
    validationSchema: LoginSchema,
  });
  const { enqueueSnackbar } = useSnackbar();

  const disabled = shouldDisabledButton(watch, errors);

  const onSubmit = (data: any) => {
    setLoading(true);
    Auth.login(data)
      .then(({ data }) => {
        if (data && data.authId) {
          setLoginInfo(data);
          appStore.setCurrentUser(data, false);
        }
        if (appStore.alreadySetup) {
          if (!appStore.alreadyChoosePlan) {
            history.push("/choose-plan");
          } else {
            history.push("/account-overview");
          }
        } else {
          history.push("/setup");
        }
      })
      .catch(error => {
        console.log("error", error);
        setLoading(false);
        setError("password", "invalid password", error.message);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });
  };

  const continueWithGoogle = () => {
    onClickGoogleLogin(googleUser => {
      if (googleUser) {
        const { idToken, email } = googleUser;
        Auth.login({ googleToken: idToken, email }).then(data => {
          console.log("data", data);
        });
      }
    });
  };

  return (
    <SimpleCardPage>
      <Box className={headerClasses.title}>Login</Box>
      <Box className={headerClasses.description}>
        Welcome back! Log in to continue.
      </Box>
      <form className={classes.form} noValidate>
        <TextField
          inputRef={register}
          error={!!errors.email}
          helperText={get(errors, "email.message")}
          className={classes.input}
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          onInput={() => clearError("password")}
          autoFocus
        />
        <AdornmentInput
          label="Password"
          className={classes.input}
          id="filled-adornment-password"
          type={showPassword ? "text" : "password"}
          inputRef={register}
          error={!!get(errors, "password")}
          helperText={get(errors, "password.message", "") as string}
          name="password"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {showPassword ? <EyeOpen /> : <EyeClose />}
              </IconButton>
            </InputAdornment>
          }
          fullWidth
        />
        {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
        <LoadingButton
          loading={loading}
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={handleSubmit(onSubmit)}
          className={classes.login}>
          Login
        </LoadingButton>
        <Grid container direction="column" alignItems="center">
          <Grid item xs>
            <NavLink to="/forget-password" className={classes.forgetPassword}>
              Forgot Password?
            </NavLink>
          </Grid>
          <Grid item xs className={classes.or}>
            or
          </Grid>
        </Grid>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="secondary"
          onClick={continueWithGoogle}
          className={classes.continueWithGoogle}
          startIcon={
            <div className={classes.googleIcon}>
              <img src="/images/google.svg" alt="google" width="30"></img>
            </div>
          }>
          Continue with Google
        </Button>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <NavLink
              to="/signup"
              className={classes.doNotHaveAccount}
              onMouseDown={() => {
                unregister("email");
              }}>
              {"Don't have an account? "}
              <strong>Sign Up</strong>
            </NavLink>
          </Grid>
        </Grid>
      </form>
    </SimpleCardPage>
  );
}
