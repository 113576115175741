import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function CalendarPicker(props) {
  return (
    <SvgIcon
      width="16"
      height="16"
      style={{
        color: "transparent",
      }}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M4.66671 8H3.33337V8.66667H4.66671V8Z"
        stroke="#323D47"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66671 8H7.33337V8.66667H8.66671V8Z"
        stroke="#323D47"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66671 11.3333H3.33337V11.9999H4.66671V11.3333Z"
        stroke="#323D47"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66671 11.3333H7.33337V11.9999H8.66671V11.3333Z"
        stroke="#323D47"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6667 8H11.3334V8.66667H12.6667V8Z"
        stroke="#323D47"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask id="path-7-inside-1" fill="white">
        <path d="M4.66671 8H3.33337V8.66667H4.66671V8Z" />
      </mask>
      <path d="M4.66671 8H3.33337V8.66667H4.66671V8Z" fill="#323D47" />
      <path
        d="M3.33337 8V7H2.33337V8H3.33337ZM4.66671 8H5.66671V7H4.66671V8ZM4.66671 8.66667V9.66667H5.66671V8.66667H4.66671ZM3.33337 8.66667H2.33337V9.66667H3.33337V8.66667ZM3.33337 9H4.66671V7H3.33337V9ZM3.66671 8V8.66667H5.66671V8H3.66671ZM4.66671 7.66667H3.33337V9.66667H4.66671V7.66667ZM4.33337 8.66667V8H2.33337V8.66667H4.33337Z"
        fill="#323D47"
        mask="url(#path-7-inside-1)"
      />
      <mask id="path-9-inside-2" fill="white">
        <path d="M8.66671 8H7.33337V8.66667H8.66671V8Z" />
      </mask>
      <path d="M8.66671 8H7.33337V8.66667H8.66671V8Z" fill="#323D47" />
      <path
        d="M7.33337 8V7H6.33337V8H7.33337ZM8.66671 8H9.66671V7H8.66671V8ZM8.66671 8.66667V9.66667H9.66671V8.66667H8.66671ZM7.33337 8.66667H6.33337V9.66667H7.33337V8.66667ZM7.33337 9H8.66671V7H7.33337V9ZM7.66671 8V8.66667H9.66671V8H7.66671ZM8.66671 7.66667H7.33337V9.66667H8.66671V7.66667ZM8.33337 8.66667V8H6.33337V8.66667H8.33337Z"
        fill="#323D47"
        mask="url(#path-9-inside-2)"
      />
      <mask id="path-11-inside-3" fill="white">
        <path d="M4.66671 11.3333H3.33337V11.9999H4.66671V11.3333Z" />
      </mask>
      <path
        d="M4.66671 11.3333H3.33337V11.9999H4.66671V11.3333Z"
        fill="#323D47"
      />
      <path
        d="M3.33337 11.3333V10.3333H2.33337V11.3333H3.33337ZM4.66671 11.3333H5.66671V10.3333H4.66671V11.3333ZM4.66671 11.9999V12.9999H5.66671V11.9999H4.66671ZM3.33337 11.9999H2.33337V12.9999H3.33337V11.9999ZM3.33337 12.3333H4.66671V10.3333H3.33337V12.3333ZM3.66671 11.3333V11.9999H5.66671V11.3333H3.66671ZM4.66671 10.9999H3.33337V12.9999H4.66671V10.9999ZM4.33337 11.9999V11.3333H2.33337V11.9999H4.33337Z"
        fill="#323D47"
        mask="url(#path-11-inside-3)"
      />
      <mask id="path-13-inside-4" fill="white">
        <path d="M8.66671 11.3333H7.33337V11.9999H8.66671V11.3333Z" />
      </mask>
      <path
        d="M8.66671 11.3333H7.33337V11.9999H8.66671V11.3333Z"
        fill="#323D47"
      />
      <path
        d="M7.33337 11.3333V10.3333H6.33337V11.3333H7.33337ZM8.66671 11.3333H9.66671V10.3333H8.66671V11.3333ZM8.66671 11.9999V12.9999H9.66671V11.9999H8.66671ZM7.33337 11.9999H6.33337V12.9999H7.33337V11.9999ZM7.33337 12.3333H8.66671V10.3333H7.33337V12.3333ZM7.66671 11.3333V11.9999H9.66671V11.3333H7.66671ZM8.66671 10.9999H7.33337V12.9999H8.66671V10.9999ZM8.33337 11.9999V11.3333H6.33337V11.9999H8.33337Z"
        fill="#323D47"
        mask="url(#path-13-inside-4)"
      />
      <mask id="path-15-inside-5" fill="white">
        <path d="M12.6667 8H11.3334V8.66667H12.6667V8Z" />
      </mask>
      <path d="M12.6667 8H11.3334V8.66667H12.6667V8Z" fill="#323D47" />
      <path
        d="M11.3334 8V7H10.3334V8H11.3334ZM12.6667 8H13.6667V7H12.6667V8ZM12.6667 8.66667V9.66667H13.6667V8.66667H12.6667ZM11.3334 8.66667H10.3334V9.66667H11.3334V8.66667ZM11.3334 9H12.6667V7H11.3334V9ZM11.6667 8V8.66667H13.6667V8H11.6667ZM12.6667 7.66667H11.3334V9.66667H12.6667V7.66667ZM12.3334 8.66667V8H10.3334V8.66667H12.3334Z"
        fill="#323D47"
        mask="url(#path-15-inside-5)"
      />
      <path
        d="M14 2H2.00002C1.26364 2 0.666687 2.59695 0.666687 3.33333V13.3333C0.666687 14.0697 1.26364 14.6667 2.00002 14.6667H14C14.7364 14.6667 15.3334 14.0697 15.3334 13.3333V3.33333C15.3334 2.59695 14.7364 2 14 2Z"
        stroke="#323D47"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 0.666626V2.66663"
        stroke="#323D47"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 0.666626V2.66663"
        stroke="#323D47"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.666687 5.33325H15.3334"
        stroke="#323D47"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
