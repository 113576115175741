import React, { ReactNode } from "react";
import DialogContext from "./DialogContext";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import last from "lodash/last";
import { noop } from "lodash-es";
import "./index.scss";
import clsx from "clsx";

export interface DialogConfig {
  className?: string;
  title?: string;
  subTitle?: string;
  description?: string;
  content?: ReactNode;
  cancelText?: string;
  confirmText?: string;
  component?: React.FC<CustomModalComponentProps>;
  injectProps?: any;
}

export interface CustomModalComponentProps {
  handleCancel: () => void;
  handleConfirm: () => void;
  handleOutSideClickClose?(): void;
}

interface DialogState extends DialogConfig {
  handleCancel(): void;
  handleConfirm(): void;
  handleOutSideClickClose?(): void;
}

interface Props {
  children: ReactNode;
}

const DialogProvider = ({ children }: Props) => {
  const [currentDialogOpen, setCurrentDialogOpen] = React.useState(false);
  const [queue, setQueue] = React.useState([] as DialogState[]);

  React.useEffect(() => {
    if (queue.length > 0 && !currentDialogOpen) {
      setCurrentDialogOpen(true);
    }
  }, [queue]);

  const addToQueue = (dialog: DialogState) => {
    setQueue([...queue, dialog]);
  };
  const removeFromQueue = () => {
    setQueue(queue.slice(0, -1));
  };
  const closeDialog = () => {
    removeFromQueue();
    setCurrentDialogOpen(false);
  };
  const openDialog = (dialogConfig: DialogConfig): Promise<any> => {
    return new Promise((resolve, reject) => {
      const handleOutSideClickClose = () => {
        reject(false);
        closeDialog();
      };
      const handleCancel = () => {
        resolve(false);
        closeDialog();
      };
      const handleConfirm = () => {
        setCurrentDialogOpen(false);
        resolve(true);
      };
      const dialogState = {
        handleCancel,
        handleConfirm,
        handleOutSideClickClose,
        cancelText: "Cancel",
        confirmText: "Confirm",
        ...dialogConfig,
      };
      addToQueue(dialogState);
    });
  };

  const [contextValue] = React.useState({ openDialog, closeDialog });

  const {
    className,
    title,
    subTitle,
    description,
    content,
    cancelText,
    confirmText,
    handleCancel,
    handleConfirm,
    handleOutSideClickClose,
    injectProps = {},
    component: Component,
  } = last(queue) || { handleCancel: noop, handleConfirm: noop };

  return (
    <DialogContext.Provider value={contextValue}>
      {children}
      <div id="dialog-protal">
        {queue.length ? (
          <Dialog
            className={clsx(className, "dialog-wrapper")}
            open={currentDialogOpen}
            onClose={handleOutSideClickClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            {Component ? (
              <Component
                handleCancel={handleCancel}
                handleConfirm={handleConfirm}
                {...injectProps}></Component>
            ) : (
              <div className="common-confirm-dialog">
                <div className="dialog-sub-title">{subTitle}</div>
                <div className="dialog-title">{title}</div>
                <div className="dialog-description">{description}</div>
                <div className="dialog-content">{content}</div>
                <div className="dialog-actions">
                  <Button
                    className="dialog-btn-cancel"
                    type="button"
                    onClick={handleCancel}>
                    {cancelText}
                  </Button>
                  <Button
                    className="dialog-btn-confirm"
                    type="button"
                    onClick={handleConfirm}
                    autoFocus>
                    {confirmText}
                  </Button>
                </div>
              </div>
            )}
          </Dialog>
        ) : null}
      </div>
    </DialogContext.Provider>
  );
};

export default DialogProvider;
