import React from "react";
import { Link } from "@material-ui/core";
import { TERMS_PRIVACY, PRIVACY, HELP_CENTER_URL } from "config";

interface Props {}

const Footer: React.FC<Props> = () => {
  return (
    <footer className="footer">
      <div className="copy-right">
        © 2018 Sunshine Health Studios LLC. All Right Reserved.
      </div>
      <div className="footer-links">
        <Link href={HELP_CENTER_URL} target="_blank">
          Help Center
        </Link>
        |
        <Link href={TERMS_PRIVACY} target="_blank">
          Terms of Service
        </Link>
        |
        <Link href={PRIVACY} target="_blank">
          Privacy Policy
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
