import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function IconCheck(props) {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M12.2807 4.28071L5.98771 10.5737L3.69471 8.28071C3.50611 8.09855 3.25351 7.99776 2.99131 8.00004C2.72911 8.00232 2.4783 8.10749 2.29289 8.29289C2.10749 8.4783 2.00232 8.72911 2.00004 8.99131C1.99776 9.25351 2.09855 9.50611 2.28071 9.69471L5.28071 12.6947C5.46824 12.8822 5.72255 12.9875 5.98771 12.9875C6.25288 12.9875 6.50718 12.8822 6.69471 12.6947L13.6947 5.69471C13.8769 5.50611 13.9777 5.25351 13.9754 4.99131C13.9731 4.72911 13.8679 4.4783 13.6825 4.29289C13.4971 4.10749 13.2463 4.00232 12.9841 4.00004C12.7219 3.99776 12.4693 4.09855 12.2807 4.28071Z"
        fill="#7ED321"
      />
    </SvgIcon>
  );
}
