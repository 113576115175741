import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Check(props) {
  return (
    <SvgIcon
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M20.59 0.562205L7.98652 13.1657L3.39414 8.57333C3.01641 8.20851 2.5105 8.00664 1.98538 8.0112C1.46026 8.01577 0.957933 8.2264 0.586601 8.59773C0.215269 8.96906 0.00463889 9.47138 7.57104e-05 9.99651C-0.00448747 10.5216 0.197382 11.0275 0.562205 11.4053L6.57055 17.4136C6.94613 17.7891 7.45545 18 7.98652 18C8.51758 18 9.02691 17.7891 9.40248 17.4136L23.422 3.39414C23.7868 3.01641 23.9887 2.5105 23.9841 1.98538C23.9795 1.46026 23.7689 0.957933 23.3976 0.586601C23.0262 0.215269 22.5239 0.00463889 21.9988 7.57104e-05C21.4737 -0.00448747 20.9678 0.197382 20.59 0.562205V0.562205Z"
        fill="#7ED321"
      />
    </SvgIcon>
  );
}
