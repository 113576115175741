import { observable, computed, toJS, action } from "mobx";
import { User, Progress } from "interfaces/user.interface";
import { forEach, sortBy, last, get } from "lodash-es";
import { clearUserInfo } from "helpers/authstorage";
import { differenceInYears } from "date-fns";
import { SignUpInput } from "api/Auth";
import { inchesToUnit, lbsToUnit } from "helpers/calculate";
import { trackUserProfile } from "helpers/appTrack";
import { StripePlan } from "interfaces/plan.interface";

export class AppStore {
  @computed
  get isAuthencated() {
    return !!this.currentUser.authId;
  }
  @computed
  get alreadySetup() {
    return !!this.currentUser.birthDate;
  }
  @computed
  get alreadyChoosePlan() {
    return !!this.currentUser.authId;
  }
  @observable currentUser: User = {};
  @action setCurrentUser(data: any, needTrack: boolean) {
    this.currentUser = mapUserFields(data);
    needTrack && trackUserProfile(this.currentUser);
  }
  @action clearCurrentUser() {
    this.currentUser = {};
  }
  @action logout() {
    clearUserInfo();
    this.setCurrentUser({}, false);
  }

  @observable signUpInfo: SignUpInput = {
    email: "",
  };
  @action clearSignUpInfo() {
    this.signUpInfo = {
      email: "",
    };
  }

  @observable stripePlan: StripePlan = {};
  @action setStripePlan = plan => {
    this.stripePlan = plan;
  };

  @observable isAppLoading = false;
  @action setAppLoading = flag => {
    this.isAppLoading = flag;
  };
}

export const mapUserFields = (data: any) => {
  forEach(dataTansformMap, ([fn, refKey]: any[], key) => {
    data[key] = fn(data[refKey || key], data);
  });
  return data;
};

export const cleanFields = (data: any) => {
  const cleanData = { ...data };
  delete cleanData.age;
  delete cleanData.recentWeight;
  delete cleanData.height;
  delete cleanData.startWeight;
  delete cleanData.goalWeight;
  return cleanData;
};

const dataTansformMap = {
  gender: [Number],
  lossPlan: [Number],
  weightUnit: [Number],
  goalWeightLbs: [Number],
  startWeightLbs: [Number],
  heightUnit: [Number],
  heightInch: [Number],
  fitnessGoal: [Number],
  fatAllowancePercent: [Number],
  carbsAllowancePercent: [Number],
  proteinAllowancePercent: [Number],
  activityLevel: [Number],
  secondaryMetric: [Number],

  // computed temp fields
  age: [
    (birthDate: string) => {
      return differenceInYears(new Date(), new Date(birthDate!));
    },
    "birthDate",
  ],
  firstName: [(name: string) => (name && name.split(" ")[0]) || "", "name"],
  lastName: [(name: string) => (name && name.split(" ")[1]) || "", "name"],
  recentWeight: [
    (progress: Progress[], data: any) => {
      const progressLast = last(
        sortBy(progress, item => +new Date(item.trackerDate)),
      );
      return Number(
        progressLast ? progressLast.weightLbs : data.startWeightLbs,
      );
    },
    "progress",
  ],
  height: [(_, data) => inchesToUnit(data.heightInch, data.heightUnit, 1)],
  startWeight: [(_, data) => lbsToUnit(data.startWeightLbs, data.heightUnit)],
  goalWeight: [(_, data) => lbsToUnit(data.goalWeightLbs, data.heightUnit)],
  isProUser: [(_, data) => get(data, "subscription.pro")],
  isPurchasedApp: [
    (_, data) => +new Date(get(data, "dateCreated")) < 1519315200,
  ],
};

export const appStore = new AppStore();

if (process.env.NODE_ENV === "development") {
  (window as any).appStore = appStore;
  (window as any).getAppStore = () => toJS(appStore);
}
