import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function EyeOpen(props) {
  return (
    <SvgIcon
      viewBox="-4 -4 24 24"
      width="16"
      height="16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g opacity="0.3">
        <path
          d="M7.9999 14C11.5999 14 14.3999 10.9 15.5999 9.1C16.0999 8.4 16.0999 7.5 15.5999 6.8C14.3999 5.1 11.5999 2 7.9999 2C4.3999 2 1.5999 5.1 0.399902 6.9C-0.100098 7.6 -0.100098 8.5 0.399902 9.1C1.5999 10.9 4.3999 14 7.9999 14ZM7.9999 5C9.6999 5 10.9999 6.3 10.9999 8C10.9999 9.7 9.6999 11 7.9999 11C6.2999 11 4.9999 9.7 4.9999 8C4.9999 6.3 6.2999 5 7.9999 5Z"
          fill="#434343"
        />
      </g>
    </SvgIcon>
  );
}
