import { LossPlan } from "config/enum";
import { find } from "lodash-es";

export const lossPlans = [
  {
    title: "Better Balance",
    className: "better-balance",
    subtitle: "Prioritize fresh, clean eating with minimal ingredients",
    content:
      "Based on calories, sat. fat, sugar, protein includes 200+ additional zero bites food",
    description:
      "Prioritize quality over quantity with a focus on fresh, clean eating.",
    features: [
      "Sustainable weight control",
      "Refreshed body",
      "Reduced sugar-dependency",
    ],
    recommended: false,
    lossPlan: LossPlan.BETTER_BALANCE,
    learnMore: false,
    isPro: true,
    selected: false,
  },
  {
    title: "Keeping Keto",
    className: "keeping-keto",
    subtitle: "Go low-carb, high fat to accelerate fat burn",
    content: "High fat, moderate protein & very low carbs",
    description:
      "Opt for a low-carb, high-fat diet to make your body more effient at burning fat.",
    features: [
      "Reduced sugar-dependency",
      "Accelerate fat burn",
      "Achieve ketosis",
    ],
    recommended: false,
    lossPlan: LossPlan.KEEPING_KETO,
    learnMore: false,
    isPro: true,
    selected: false,
  },
  {
    title: "Sugar Smart",
    className: "sugar-smart",
    subtitle:
      "Kick your sugar addiction and get lean with this no-nonsense plan.",
    content: "Based on calories, sat. fat, sugar, protein",
    description:
      "Kick your sugar addiction and get lean with this no-nonsense plan.",
    features: [
      "Reduced sugar-dependency",
      "Sustainable weight control",
      "Refreshed body",
    ],
    recommended: false,
    lossPlan: LossPlan.SUGAR_SMART,
    learnMore: false,
    isPro: false,
    selected: false,
  },
  {
    title: "Conquer Cravings",
    className: "conquer-cravings",
    subtitle: "Establish healthy habits through portion control",
    content: "Based on calories, fat, and fiber",
    description:
      "Establish healthy eating habits with an emphasis on portion control.",
    features: ["Manage cravings", "No off-limit foods", "Generally healthy"],
    recommended: false,
    lossPlan: LossPlan.CONQUER_CRAVINGS,
    learnMore: false,
    isPro: false,
    selected: false,
  },
  {
    title: "Carb Conscious",
    className: "carb-conscious",
    subtitle: "Go low-carb to achieve optimal energy levels",
    content: "Based on protein, carb, fat, and fiber",
    description:
      "Go low-carb for steady weight loss and optimal energy levels.",
    features: [
      "Stabilized energy levels",
      "Regularized blood sugar",
      "Reduced sugar-dependency",
    ],
    recommended: false,
    lossPlan: LossPlan.CARB_CONSCIOUS,
    learnMore: false,
    isPro: false,
    selected: false,
  },
  {
    title: "Calorie Command",
    className: "calorie-command",
    subtitle: "Stick to classic calorie-focused plan",
    content: "Tracking your intakes by counting calories",
    description: "Stick to a classic calorie-focused, fuss-free plan.",
    features: [
      "No off-limit foods",
      "Generally healthy",
      "Exercise portion control",
    ],
    recommended: false,
    lossPlan: LossPlan.CALORIE_COMMAND,
    learnMore: false,
    isPro: false,
    selected: false,
  },
];

export const getPlan = (lossPlan?: number) => {
  return lossPlans.find(plan => plan.lossPlan === lossPlan) || lossPlans[0];
};

export const isBitesPlan = lossPlan =>
  [LossPlan.CALORIE_COMMAND, LossPlan.KEEPING_KETO].indexOf(lossPlan) === -1;
