import React, { ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

const Wrapper: React.FC<Props> = ({ children }) => {
  return (
    <main
      style={{
        height: "100vh",
        width: "100vw",
        overflowX: "hidden",
        position: "relative",
      }}>
      {children}
    </main>
  );
};

export default Wrapper;
