import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Male(props) {
  return (
    <SvgIcon
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M15.657 8.343L22 2"
        stroke="#959FAE"
        strokeWidth="2"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 2H22V8"
        stroke="#959FAE"
        strokeWidth="2"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 22C14.4183 22 18 18.4183 18 14C18 9.58172 14.4183 6 10 6C5.58172 6 2 9.58172 2 14C2 18.4183 5.58172 22 10 22Z"
        stroke="#959FAE"
        fill="none"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
