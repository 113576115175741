import React, { ReactNode } from "react";
import TopBar from "./TopBar";
import Footer from "./Footer";
import { makeStyles } from "@material-ui/core";
import Wrapper from "./Wrapper";

interface Props {
  children?: ReactNode;
}

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
  },
  mask1: {
    pointerEvents: "none",
    userSelect: "none",
    position: "fixed",
    top: 0,
    right: 0,
  },
  mask2: {
    pointerEvents: "none",
    userSelect: "none",
    position: "fixed",
    bottom: 0,
    left: 0,
  },
}));

const SingleLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TopBar type="single" />
      <img src="/images/mask1.svg" alt="mask" className={classes.mask1} />
      <img src="/images/mask2.svg" alt="mask" className={classes.mask2} />
      <Wrapper>{children}</Wrapper>
    </div>
  );
};

export default SingleLayout;
