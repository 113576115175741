import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { NavLink, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles, Theme } from "@material-ui/core/styles";
import SimpleCardPage, { useHeaderStyles } from "components/SimpleCradPage";
import { useForm, OnSubmit } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
  Link,
} from "@material-ui/core";
import { get } from "lodash-es";
import * as yup from "yup";
import LoadingButton from "components/LoadingButton";
import { useSnackbar } from "notistack";
import { onClickGoogleLogin } from "api/GoogleAuth";
import { setLoginInfo } from "helpers/authstorage";
import { PRIVACY, TERMS_PRIVACY } from "config";
import EyeOpen from "icons/eye-open";
import EyeClose from "icons/eye-close";
import { shouldDisabledButton } from "helpers/common";
import { checkSignUp, SignUpInput, createAccount } from "api/Auth";
import { appStore } from "models";

const SignUpSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup
    .string()
    .required()
    .email("Invalid email"),
  password: yup.string().required("Password is required"),
});

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: "290px",
  },
  signup: {
    margin: theme.spacing(2, 0, 1),
  },
  continueWithGoogle: {
    margin: theme.spacing(2, 0, 2),
    paddingLeft: 44,
    textTransform: "none",
  },
  input: {
    margin: "0px 0px 10px 0px",
  },
  googleIcon: {
    backgroundColor: "white",
    height: 44,
    width: 44,
    borderRadius: 2,
    top: "3px",
    left: "3px",
    position: "absolute",
    padding: 7,
  },
  forgetPassword: {
    color: theme.palette.text.hint,
    fontSize: 12,
    fontWeight: 800,
  },
  or: {
    color: theme.palette.text.disabled,
    paddingTop: 8,
  },
  alreadyHaveAccount: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
  acceptTermText: {
    position: "absolute",
    width: "100%",
    bottom: 10,
    fontSize: 12,
    color: theme.palette.text.disabled,
    textAlign: "center",
    transform: "scale(0.9)",
  },
  termLink: {
    color: theme.palette.text.primary,
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    errors,
    setError,
    unregister,
  } = useForm<SignUpInput>({
    mode: "onBlur",
    validationSchema: SignUpSchema,
    defaultValues: {
      name: "",
      email: "",
      password: "",
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit: OnSubmit<SignUpInput> = (data: SignUpInput) => {
    setLoading(true);
    // const createAccountParam = assign({ gender: Gender.FEMALE }, data);
    // Auth.createAccount(createAccountParam)
    //   .then(data => {
    //     if (data.authId) {
    //       setLoginInfo(data);
    //       history.push("/setup");
    //     }
    //   })
    //   .catch(error => {
    //     setLoading(false);
    //     setError("password", "", error.message);
    //     enqueueSnackbar(error.message, {
    //       variant: "error",
    //     });
    //   });
    checkSignUp(data.email)
      .then((result: any = {}) => {
        if (result.success) {
          history.push("/setup");
          appStore.signUpInfo = data;
        } else {
          setLoading(false);
          setError("email", "", result.message);
          enqueueSnackbar(result.message, {
            variant: "error",
          });
        }
      })
      .catch(error => {
        setLoading(false);
        setError("password", "", error.message);
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      });
  };

  const continueWithGoogle = () => {
    onClickGoogleLogin(googleUser => {
      if (googleUser) {
        const { email, idToken } = googleUser;
        checkSignUp(email)
          .then((result: any = {}) => {
            if (result.success) {
              history.push("/setup");
              const data = { email: email, googleToken: idToken };
              appStore.signUpInfo = data;
            } else {
              setLoading(false);
              setError("email", "", result.message);
              enqueueSnackbar(result.message, {
                variant: "error",
              });
            }
          })
          .catch(error => {
            setLoading(false);
            setError("password", "", error.message);
            enqueueSnackbar(error.message, {
              variant: "error",
            });
          });
        // createAccount({
        //   user: {
        //     name,
        //     email,
        //     profilePhotoUrl,
        //   },
        //   name,
        //   email,
        //   // password: "1",
        //   googleToken: idToken,
        // }).then(() => {
        //   setLoginInfo({});
        // });
      }
    });
  };

  const disabled = shouldDisabledButton(watch, errors);

  return (
    <SimpleCardPage>
      <Box className={headerClasses.title}>Sign Up</Box>
      <Box className={headerClasses.description}>
        Create an account to get started.
      </Box>
      <form className={classes.form} noValidate>
        <TextField
          inputRef={register}
          className={classes.input}
          variant="outlined"
          margin="normal"
          fullWidth
          id="name"
          label="Name"
          name="name"
          error={!!errors.name}
          helperText={get(errors, "name.message")}
          autoComplete="name"
          autoFocus
        />
        <TextField
          inputRef={register}
          className={classes.input}
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="Email"
          name="email"
          error={!!errors.email}
          helperText={get(errors, "email.message")}
          autoComplete="email"
        />
        <FormControl className={classes.input} variant="outlined" fullWidth>
          <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="filled-adornment-password"
            type={showPassword ? "text" : "password"}
            inputRef={register}
            error={!!errors.password}
            name="password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {showPassword ? <EyeOpen /> : <EyeClose />}
                </IconButton>
              </InputAdornment>
            }
            fullWidth
          />
          <FormHelperText error={!!errors.password}>
            {get(errors, "password.message", "")}
          </FormHelperText>
        </FormControl>
        {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
        <LoadingButton
          loading={loading}
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          disabled={disabled}
          onClick={handleSubmit(onSubmit)}
          className={classes.signup}>
          Sign Up
        </LoadingButton>
        <Grid container direction="column" alignItems="center">
          <Grid item xs className={classes.or}>
            or
          </Grid>
        </Grid>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.continueWithGoogle}
          onClick={continueWithGoogle}
          startIcon={
            <div className={classes.googleIcon}>
              <img src="/images/google.svg" alt="google" width="30"></img>
            </div>
          }>
          Continue with Google
        </Button>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <NavLink
              to="/login"
              className={classes.alreadyHaveAccount}
              onMouseDown={() => {
                unregister("name");
              }}>
              {"Already have an account? "}
              <strong>Log In</strong>
            </NavLink>
          </Grid>
        </Grid>
      </form>

      <div className={classes.acceptTermText}>
        {"By continuing, you are indicating that you accept our "}
        <Link href={TERMS_PRIVACY} target="_blank" className={classes.termLink}>
          Terms of Service
        </Link>
        {" and "}
        <Link href={PRIVACY} target="_blank" className={classes.termLink}>
          Privacy Policy
        </Link>
        {"."}
      </div>
    </SimpleCardPage>
  );
}
