import React, { useEffect } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import SimpleCardPage, { useHeaderStyles } from "components/SimpleCradPage";
import { StepConnector, StepIconProps, Box } from "@material-ui/core";
import Check from "icons/check";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import SelectGender from "./SelectGender";
import PersonalDetail from "./PersonalDetail";
import Habits from "./Habits";
import { useForm, Controller, FormContext } from "react-hook-form";
import * as yup from "yup";
import { createAccount, getUserInfo } from "api/Auth";
import LoadingButton from "components/LoadingButton";
import { assign } from "lodash-es";
import { shouldDisabledButton } from "helpers/common";
import {
  getCreateUser,
  getInches,
  inchesToUnit,
  getLbs,
  lbsToUnit,
} from "helpers/calculate";
import { setLoginInfo } from "helpers/authstorage";
import { useSnackbar } from "notistack";
import { appStore } from "models";
import { observer } from "mobx-react";
import { validateHeight, validateWeight } from "helpers/validate";
import { appInitGetUserInfo } from "models/actions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stepper: {
      position: "absolute",
      top: 31,
      width: 215,
      padding: 0,
      "& .MuiStepLabel-labelContainer": {
        display: "none",
      },
      "& .MuiStepLabel-iconContainer": {
        padding: 0,
      },
      "& .MuiStep-horizontal": {
        padding: 0,
      },
    },
    form: {
      width: 290,
    },
    button: {
      width: 290,
      marginTop: 40,
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }),
);

const QontoConnector = withStyles({
  active: {
    "& $line": {
      borderColor: "#61A6F5",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#61A6F5",
    },
  },
  line: {
    borderColor: "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    width: 21,
    height: 21,
    padding: 0,
  },
  circle: {
    width: 21,
    height: 21,
    borderRadius: "50%",
    border: "#E1E8EF 3px solid",
  },
  active: {
    background: "#61A6F5",
    border: "white 6px solid",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.07)",
  },
  completed: {
    background: "#61A6F5",
    border: "none",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.05)",
  },
  check: {
    width: "100%",
    height: "100%",
    padding: 5,
    "& path": {
      fill: "white",
    },
  },
});

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, classes.circle, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}>
      {completed && <Check className={classes.check}></Check>}
    </div>
  );
}

const steps = [
  {
    key: "gender",
    title: "Let’s start by getting to know you.",
    description:
      "We’ll use this information to help us create a program that aligns to your individual needs. ",
    fields: ["gender"],
  },
  {
    key: "detail",
    title: "Personal Details",
    description: "Can you tell us more about you?",
    fields: [
      "birthDate",
      "height",
      "startWeight",
      "goalWeight",
      "activityLevel",
    ],
  },
  {
    key: "standing",
    title: "What’s standing in your way?",
    description:
      "This will help us create a program that aligns with your individual needs.",
    fields: ["habits"],
  },
];

const watchRef: any = {
  current: {},
};

const getCurrentWatchRef = () => watchRef.current;

const SetupSchema = yup.object().shape({
  gender: yup.number().required(),
  heightUnit: yup.number().required(),
  weightUnit: yup.number().required(),
  birthDate: yup.string().required(),
  height: yup
    .string()
    .required()
    .test(validateHeight(getCurrentWatchRef) as any),
  startWeight: yup
    .string()
    .required()
    .test(validateWeight(getCurrentWatchRef) as any),
  goalWeight: yup
    .string()
    .required()
    .test(validateWeight(getCurrentWatchRef) as any),
  activityLevel: yup.string().required(),
});

const Setup = () => {
  const classes = useStyles();
  const headerClasses = useHeaderStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({
    mode: "onBlur",
    validationSchema: SetupSchema,
    defaultValues: {
      gender: undefined,

      heightUnit: 0,
      weightUnit: 0,

      birthDate: "",
      age: undefined,
      heightInch: undefined,
      height: "",
      startWeightLbs: undefined,
      startWeight: "",
      goalWeightLbs: undefined,
      goalWeight: "",
      activityLevel: "",

      habits: [],
    },
  });
  const { control, getValues, watch, errors, setValue } = methods;
  watchRef.current = watch();
  useEffect(() => {
    if (!appStore.signUpInfo.email) {
      history.push("/signup");
    }
  }, []);
  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    } else {
      setLoading(true);
      const data = getValues();
      const createAccountParam = assign(
        {
          user: getCreateUser(data),
        },
        appStore.signUpInfo,
      );
      createAccount(createAccountParam)
        .then(result => {
          if (result.authId) {
            appStore.clearCurrentUser();
            setLoginInfo(result);
            appInitGetUserInfo(result.id, true).then(() => {
              history.push("/choose-plan");
              appStore.clearSignUpInfo();
            });
          }
        })
        .catch(error => {
          setLoading(false);
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        });
    }
  };
  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    } else {
      history.goBack();
    }
  };
  const onUnitChange = (unitType: string, currentUnit: number) => {
    if (unitType === "heightUnit") {
      const { height, heightUnit } = watchRef.current;
      height &&
        setValue(
          "height",
          inchesToUnit(getInches(height, heightUnit), currentUnit, 1) as any,
        );
    } else if (unitType === "weightUnit") {
      const { startWeight, goalWeight, weightUnit } = watchRef.current;
      startWeight &&
        setValue(
          "startWeight",
          lbsToUnit(getLbs(startWeight, weightUnit), currentUnit, 1) as any,
        );
      goalWeight &&
        setValue(
          "goalWeight",
          lbsToUnit(getLbs(goalWeight, weightUnit), currentUnit, 1) as any,
        );
    }
  };
  const disabled =
    shouldDisabledButton(watch, errors, steps[activeStep].fields) &&
    activeStep !== 2;

  return (
    <SimpleCardPage onBackClick={handleBack} style={{ paddingBottom: 31 }}>
      <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        connector={<QontoConnector />}>
        {steps.map(step => (
          <Step key={step.key}>
            <StepLabel StepIconComponent={QontoStepIcon}>{null}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box className={headerClasses.title} style={{ width: 354 }}>
        {steps[activeStep].title}
      </Box>
      <Box
        className={headerClasses.description}
        style={{ width: 412, marginBottom: 0 }}>
        {steps[activeStep].description}
      </Box>
      <Box display={steps[activeStep].key === "gender" ? "block" : "none"}>
        <Controller
          as={SelectGender}
          name="gender"
          control={control}
          onChange={([gender]) => gender}
        />
      </Box>
      <Box display={steps[activeStep].key === "detail" ? "block" : "none"}>
        <form className={classes.form} noValidate>
          <FormContext {...methods}>
            <PersonalDetail onUnitChange={onUnitChange} />
          </FormContext>
        </form>
      </Box>
      <Box display={steps[activeStep].key === "standing" ? "block" : "none"}>
        <Controller as={Habits} name="habits" control={control} />
      </Box>
      <LoadingButton
        loading={loading}
        variant="contained"
        fullWidth
        color="primary"
        onClick={handleNext}
        disabled={disabled}
        className={classes.button}>
        Continue
      </LoadingButton>
    </SimpleCardPage>
  );
};

export default observer(Setup);
