import React from "react";
import { FormControl, FormHelperText } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import NumberFormat from "react-number-format";
import { get } from "lodash-es";
import IconCalendarPicker from "icons/calendar-picker";
import { subYears, format, isValid } from "date-fns";

export const ControledDatePicker = (props: any) => {
  const {
    value,
    onChange,
    onBlur,
    name,
    InputLabelProps,
    errors,
    setError,
    clearError,
    margin,
    label,
    placeholder,
  } = props;

  const handleDateChange = (_: any, value: any) => {
    clearError(name);
    const propsValue = isValid(new Date(value))
      ? format(new Date(value), "yyyy-MM-dd")
      : "";
    onChange(propsValue);
  };
  const stateValue = isValid(new Date(value))
    ? format(new Date(value), "yyyy-MM-dd")
    : null;
  return (
    <FormControl variant="outlined" fullWidth>
      <KeyboardDatePicker
        className="keyboard-datepicker"
        variant="dialog"
        margin={margin}
        inputVariant="outlined"
        inputValue=""
        id="date-picker-dialog"
        label={label}
        placeholder={placeholder}
        openTo="year"
        format="MM/dd/yyyy"
        minDate="01/01/1930"
        maxDate={subYears(new Date(), 13)}
        initialFocusedDate="06/15/2000"
        views={["year", "month", "date"]}
        onChange={handleDateChange}
        onBlur={onBlur}
        value={stateValue}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        InputLabelProps={InputLabelProps}
        onError={message => {
          if (message) {
            setError(name, message as string);
          }
        }}
        keyboardIcon={<IconCalendarPicker />}
        autoOk
        clearable
        fullWidth
      />
      <FormHelperText error={!!errors[name]}>
        {get(errors, `${name}.message`, "")}
      </FormHelperText>
    </FormControl>
  );
};

export const mixErrorProps = <P extends object>(
  ComponentT: React.FC<P & {}>,
) => (props: any) => {
  const errorProps = {
    error: !!get(props.errors, props.name),
    helperText: get(props.errors, `${props.name}.message`),
  };
  return <ComponentT {...props} {...errorProps}></ComponentT>;
};

export const NumberFormatU = React.memo(NumberFormat);

export const NumberFormatWithError = mixErrorProps(
  (NumberFormatU as unknown) as React.FC,
);
