import React from "react";
import "./index.scss";
import clsx from "clsx";
import { getPlan } from "config/plans";

interface Props {
  lossPlan: number;
}

const InlinePlanCard: React.FC<Props> = ({ lossPlan }) => {
  const plan = getPlan(lossPlan);
  return (
    <div
      key={plan.title}
      className={clsx("plan-card-component inline", plan.className, {
        pro: plan.isPro,
      })}>
      <div className="card-left plan-bgc">
        <div className="plan-bg card-inline-bg-position"></div>
        <div className="card-inline-content">
          {plan.isPro && <div className="card-pro-badge">PRO</div>}
          <div className="card-title-inline-row">
            <div className="card-title">{plan.title}</div>
            <div className="plan-icon"></div>
          </div>
          <div className="card-description">{plan.description}</div>
        </div>
      </div>
      <div className="card-features">
        {plan.features.map(text => (
          <div key={text} className="card-feature-item">
            <div className="card-feature-item-icon"></div>
            <div className="card-feature-item-text">{text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InlinePlanCard;
