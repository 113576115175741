import { getUserInfo } from "api/Auth";
import { appStore } from "models";
import { getStripePlan } from "api/Checkout";
import { get, noop } from "lodash-es";
import { updateUserInfo } from "api/Auth";
import { getPlan } from "config/plans";
import useDialog from "components/Dialog/useDialog";
import CheckoutModal from "views/Checkout";
import SubscribeSuccessModal from "views/Checkout/SubscribeSuccessModal";

export const appInitGetUserInfo = async (userId, needTrack) => {
  const user = await getUserInfo(userId);
  appStore.setCurrentUser(user, needTrack);
  const plan = await getStripePlan();
  appStore.setStripePlan(get(plan, "data[0]"));
};

export const useCreateSubscription = () => {
  const { openDialog } = useDialog();
  return async () => {
    getStripePlan().then(plan => appStore.setStripePlan(get(plan, "data[0]")));
    const result = await openDialog({
      component: CheckoutModal,
      className: "checkout-modal",
    }).catch(noop);
    if (result) {
      openDialog({
        component: SubscribeSuccessModal,
        className: "sub-success-modal",
      });
    }
    return result;
  };
};

export const useChangeUserPlan = () => {
  const handleCreateSubscription = useCreateSubscription();
  return async (plan: number, updateOnBoarding?: boolean) => {
    if (!appStore.currentUser.isProUser && getPlan(plan).isPro) {
      const result = await handleCreateSubscription();
      if (!result) {
        return Promise.reject({});
      }
    }
    return updateUserInfo({ lossPlan: plan }, updateOnBoarding);
  };
};
