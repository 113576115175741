import React from "react";
import {
  FormControl,
  OutlinedInput,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { OutlinedInputProps } from "@material-ui/core/OutlinedInput";

type Props = MyType.Overwrite<
  OutlinedInputProps,
  {
    error?: boolean;
    shrink?: boolean;
    helperText?: string;
    className?: string;
    inputClassName?: string;
    label?: string;
    margin?: "none" | "dense" | "normal";
  }
>;

const AdornmentInput: React.FC<Props> = props => {
  const {
    className,
    label,
    margin,
    inputClassName,
    error,
    shrink,
    helperText,
    ...otherProps
  } = props;
  return (
    <FormControl
      margin={margin}
      className={className}
      variant="outlined"
      fullWidth={!!props.fullWidth}>
      <InputLabel shrink={shrink} htmlFor={props.id}>
        {label}
      </InputLabel>
      <OutlinedInput {...otherProps} className={inputClassName} error={error} />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default AdornmentInput;
