import React from "react";
import "./index.scss";
import clsx from "clsx";
import { getPlan } from "config/plans";

interface Props {
  lossPlan: number;
}

const MinimalPlanCard: React.FC<Props> = ({ lossPlan }) => {
  const plan = getPlan(lossPlan);
  return (
    <div
      key={plan.title}
      className={clsx("plan-card-component minimal", plan.className, {
        pro: plan.isPro,
      })}>
      <div className="card-top plan-bgc">
        <div className="plan-bg card-bg-position"></div>
      </div>
      <div className="card-icon plan-icon plan-icon-bg"></div>
      <div className="card-content">
        <div className="card-title">{plan.title}</div>
        <div className="card-subtitle">{plan.subtitle}</div>
        {plan.isPro && <div className="card-pro-badge">PRO</div>}
      </div>
    </div>
  );
};

export default MinimalPlanCard;
