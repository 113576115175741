import React from "react";
import { Grid, Box, Button, Typography } from "@material-ui/core";
import "./index.scss";
import { observer } from "mobx-react";
import { appStore } from "models";
import useDialog from "components/Dialog/useDialog";
import { cancleSubscription } from "api/Checkout";
import { map, get, capitalize, noop } from "lodash-es";
import { getPriceString, getOriginPriceString } from "helpers/format";
import { format } from "date-fns";
import ProBadgeLg from "icons/pro-badge-lg";
import { PaymentMethod } from "config/enum";
import ProFeatureTable from "./ProFeatureTable";
import clsx from "clsx";
import ConfirmCancelSubscriptionModal from "./ConfirmCancelSubscriptionModal";
import { appInitGetUserInfo, useCreateSubscription } from "models/actions";
import UpdatePaymentMethod from "views/Checkout/UpdatePaymentMethod";

interface Props {}

const moreAboutProFeatures = [
  {
    group: "More Plans",
    features: [
      {
        name: "Better Balance Plan",
        description:
          "Prioritize quality over quantity with an emphasis on fresh, clean eating.",
        icon: "balance",
      },
      {
        name: "Keto Balance Plan",
        description:
          "Opt for a low-carb, high-fat diet to accelerate fat burn.",
        icon: "keto",
      },
    ],
  },
  {
    group: "More Tracking",
    features: [
      {
        name: "Voice Search",
        description: "Simply state the food and meal - we’ll do the rest.",
        icon: "voice",
      },
      {
        name: "Macronutrient Tracking",
        description: "Track your carb, protein, and fat intake per meal.",
        icon: "track",
      },
      {
        name: "Recipe Builder",
        description: "Create your own custom recipes.",
        icon: "recipe",
      },
      {
        name: "Fitbit Step Tracking",
        description: "Automate activity tracking with Fitbit integration.",
        icon: "fitbit",
      },
      {
        name: "Export Logs",
        description: "Download detailed stats to analyze and share.",
        icon: "export-log",
      },
    ],
  },
  {
    group: "More Resources",
    features: [
      {
        name: "Recipe Database",
        description: "Browse +500K recipes to find your perfect meal.",
        icon: "recipe",
      },
      {
        name: "Restaurant Guide",
        description: "Make healthy decisions on what you eat while eating out.",
        icon: "restaurant",
      },
      {
        name: "Snack & Beer Guides",
        description: "Search our database of +1M brand-named snacks and beers.",
        icon: "snack",
      },
    ],
  },
  {
    group: "More Support",
    features: [
      {
        name: "Community Posting",
        description: "Engage with an active online community of members.",
        icon: "community",
      },
      {
        name: "Speak to Coaches",
        description:
          "Reach out to coaches any time for questions, support, and motivation.",
        icon: "coaches",
      },
      {
        name: "Engage with Groups",
        description:
          "Groups provide an additional support system on your journey.",
        icon: "engage",
      },
    ],
  },
];

const getCardBrandName = brand => {
  const nameMap = {
    amex: "American Express",
    mastercard: "Mastercard",
    visa: "Visa",
  };
  return nameMap[brand] || capitalize(brand);
};

const ManageSubscription: React.FC<Props> = () => {
  const currentUser = appStore.currentUser;
  const isPro = currentUser.isProUser;
  const { openDialog } = useDialog();
  const handleCreateSubscription = useCreateSubscription();
  const subscriptionCancellationDate = get(
    currentUser,
    "subscription.subscriptionCancellationDate",
  );
  const subscriptionExpirationDate = get(
    currentUser,
    "subscription.subscriptionExpirationDate",
  );
  const paymentMethod = Number(get(currentUser, "subscription.paymentMethod"));
  const showPaymentMethod =
    [PaymentMethod.ANDROID, PaymentMethod.APPLE, PaymentMethod.STRIPE].indexOf(
      paymentMethod,
    ) > -1;
  const canCancelOnWeb = paymentMethod === PaymentMethod.STRIPE;
  const paymentMethodDetail = get(currentUser, "subscription.payment_method");
  const paymentMethodProp = {
    text: "",
    buttonEnable: false,
    className: "",
    icon: "",
  };
  if (paymentMethod === PaymentMethod.APPLE) {
    paymentMethodProp.text =
      "Payment method can be updated from the app store.";
    paymentMethodProp.icon = "apple";
  } else if (paymentMethod === PaymentMethod.ANDROID) {
    paymentMethodProp.text =
      "Payment method can be updated from Google Play Store.";
    paymentMethodProp.icon = "google-play";
  } else if (paymentMethod === PaymentMethod.STRIPE) {
    paymentMethodProp.className = "can-update-payment-method";
    paymentMethodProp.buttonEnable = true;
    paymentMethodProp.text = "Update payment method";
  }

  const handleCancelSubscription = () => {
    openDialog({
      component: ConfirmCancelSubscriptionModal,
    })
      .then(async result => {
        if (result) {
          await cancleSubscription();
          await appInitGetUserInfo(currentUser.id, true);
        }
      })
      .catch(noop);
  };
  const handleClickUpdatePaymentMethod = () => {
    openDialog({
      component: UpdatePaymentMethod,
      className: "update-payment-modal",
    })
      .then(async result => {
        if (result) {
          console.log("result", result);
        }
      })
      .catch(noop);
  };

  return (
    <Grid className="page-manage-subscription" spacing={6} container>
      <Grid xs={12} item>
        <Box className="content-box">
          <div className="box-sub-title">Current Subscription</div>
          {isPro ? (
            <>
              <ProBadgeLg className="membership-pro-badge" />
              <div
                className={clsx("membership-discription", {
                  "mt-20": subscriptionCancellationDate,
                })}>
                {!subscriptionCancellationDate
                  ? `Your subscription is active and your next renewal will be on ${format(
                      new Date(subscriptionExpirationDate),
                      "MMM do, yyyy",
                    )}.`
                  : `Renewal of your subscription has been canceled. You can still access PRO through ${format(
                      new Date(subscriptionExpirationDate),
                      "MMM do, yyyy",
                    )}.`}
              </div>
              {!subscriptionCancellationDate && (
                <Button
                  className="btn-membership-cta cancel-sub"
                  disabled={!canCancelOnWeb}
                  onClick={handleCancelSubscription}>
                  {canCancelOnWeb
                    ? "Cancel Subscription"
                    : "Cancel your subscription under iTrackBites app settings."}
                </Button>
              )}
            </>
          ) : (
            <>
              <Typography variant="h2" className="membership-title">
                Basic
              </Typography>
              <Typography className="membership-discription">
                Supercharge your weight loss with unlimited access to our most
                popular Pro features.
              </Typography>
              <Button
                onClick={handleCreateSubscription}
                className="btn-upgrade btn-red"
                variant="contained"
                color="primary">
                Upgrade Now
              </Button>
            </>
          )}
        </Box>
      </Grid>
      {isPro && showPaymentMethod && (
        <Grid xs={12} item>
          <Box className="content-box payment-method">
            <div className="box-sub-title">Payment method</div>
            <div className="mastercard-info">
              {`${getCardBrandName(
                get(paymentMethodDetail, "card.brand"),
              )} ending in ${get(paymentMethodDetail, "card.last4")}`}
              <img
                className="card-brand"
                src={`/images/${get(paymentMethodDetail, "card.brand")}.png`}
                alt={get(paymentMethodDetail, "card.brand")}
              />
            </div>
            <div className="expire-date">
              {`Expired Date: ${format(
                new Date(
                  get(paymentMethodDetail, "card.exp_year"),
                  get(paymentMethodDetail, "card.exp_month") - 1,
                ),
                "MM / yyyy",
              )}`}
            </div>
            {paymentMethodProp.icon && (
              <img
                className="payment-method-icon"
                src={`/images/payment-methods/${paymentMethodProp.icon}.png`}
                alt={paymentMethodProp.icon}></img>
            )}
            <Button
              className={`btn-membership-cta update-payment-method ${paymentMethodProp.className}`}
              disabled={!paymentMethodProp.buttonEnable}
              onClick={handleClickUpdatePaymentMethod}>
              {paymentMethodProp.text}
            </Button>
          </Box>
        </Grid>
      )}
      {!isPro && (
        <>
          <Grid xs={12} item>
            <Box className="content-box become-a-pro">
              <div className="become-a-pro-title">
                Become a <div className="pro-badge">Pro</div>
              </div>
              <div className="become-a-pro-sub-title">
                Save {100 - get(appStore, "stripePlan.metadata.discount")}% off
                regular PRO pricing
              </div>
              <div className="become-a-pro-price">
                <div className="original-price">
                  {getOriginPriceString(appStore.stripePlan)}
                </div>
                <div className="current-price">
                  {getPriceString(appStore.stripePlan)}
                </div>
              </div>
              <ProFeatureTable />
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box className="content-box learn-more-about-pro">
              <div className="learn-more-about-pro-header">
                <div className="learn-more-about-title">
                  Learn more about <div className="pro-badge">Pro</div>
                </div>
                <div className="learn-more-about-sub-title">
                  The PRO subscription provides all the tools and resources
                  needed to maximize your goals.
                </div>
              </div>
              <div className="learn-more-about-feature-list">
                {map(moreAboutProFeatures, ({ group, features }, index) => (
                  <div className="features-group" key={index}>
                    <div className="feature-group-title">{group}</div>
                    {map(features, ({ name, icon, description }) => (
                      <div className="feature-item" key={name}>
                        <img
                          className="feature-icon"
                          src={`images/more-about-pro/${icon}.svg`}
                          alt=""
                        />
                        <div className="feature-right">
                          <div className="feature-name">{name}</div>
                          <div className="feature-description">
                            {description}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
              <div className="learn-more-about-pro-bottom">
                <div className="learn-more-about-bottom-text">
                  You’re ready to supercharge your weight loss with our most
                  popular Pro features.
                </div>
                <Button
                  onClick={handleCreateSubscription}
                  className="btn-upgrade-bottom btn-red"
                  variant="contained"
                  color="primary">
                  Upgrade - {getPriceString(appStore.stripePlan)}/year
                </Button>
              </div>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default observer(ManageSubscription);
