import React from "react";

// Material components
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Check(props) {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g opacity="0.5">
        <path
          d="M1 1L10 10"
          stroke="#BFC6D5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 1L1 10"
          stroke="#BFC6D5"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
}
