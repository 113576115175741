import React from "react";
import { makeStyles, Theme, Box } from "@material-ui/core";
import clsx from "clsx";

interface Props {
  options?: string[];
  value: number[];
  onChange: (list: number[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 503,
    paddingLeft: 23,
    marginTop: 28,
    marginBottom: 6,
    display: "flex",
    flexFlow: "row wrap",
  },
  item: {
    marginTop: 20,
    marginRight: 23,
    width: 137,
    height: 50,
    background: "#F7F8FA",
    borderRadius: "25px",
    textAlign: "center",
    padding: "15px 0",
    fontSize: 14,
    lineHeight: "20px",
    cursor: "pointer",
    whiteSpace: "nowrap",
    "&:nth-child(5n-1)": {
      marginLeft: 68,
    },
  },
  selected: {
    backgroundColor: "#77B6FF",
    color: "white",
    fontWeight: 900,
  },
}));

const defaultOptions = [
  "Binge Eating",
  "Junk Food",
  "Sugary Food",
  "Social Support",
  "Willpower",
  "Limited Time",
  "Knowledge",
  "High Costs",
  "Social Pressure",
  "Emotional Eating",
];

const StandingPicker: React.FC<Props> = ({
  options = defaultOptions,
  value = [],
  onChange,
}) => {
  const classes = useStyles();
  const handleClick = (index: number) => (event: any) => {
    const existIndex = value.indexOf(index);
    if (existIndex > -1) {
      value.splice(existIndex, 1);
      onChange([...value]);
    } else {
      onChange([...value, index]);
    }
  };
  return (
    <Box className={classes.root}>
      {options.map((label, index) => (
        <Box
          className={clsx(classes.item, {
            [classes.selected]: value.indexOf(index) > -1,
          })}
          key={label}
          onClick={handleClick(index)}>
          {label}
        </Box>
      ))}
    </Box>
  );
};

export default StandingPicker;
